/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

/* main slider */
.slider {
	opacity: 0;
	transition: 400ms;
	margin-bottom: 0px;
	&.slick-initialized {
		opacity: 1;
	}
	.slick-slide {
		height: calc(100vh - 135px);
		overflow: hidden;
		position: relative;
		outline: none;
		&:after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background: rgba(0,0,0,0.5) url(../img/bg.png) repeat center center;
		}
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}
		.slider__block {
			width: 1170px;
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			font-size: 24px;
			text-align: center;
			color: #fff;
			padding: 0 35px;
			margin: 0 auto;
			transform: translateY(-50%);
			z-index: 1;
			.slider__block--title {
				font-family: $bold;
				font-size: 52px;
				color: #fff;
				text-transform: uppercase;
				margin: 0 0 15px;
			}
			.btn {
				margin: 50px auto 0;
			}
		}
	}
	.slick-dots {
		bottom: 30px;
		li {
			margin: 0px 2px;
			button {
				width: 15px;
				height: 15px;
				background-color: #efefef;
				border-radius: 100%;
				border: 1px solid #3361eb;
				&:before {
					display: none;
				}
				&:hover {
					background-color: #3361eb;
				}
			}
			&.slick-active {
				button {
					background-color: #3361eb;
				}
			}
		}
	}
	&.arrow .slick-arrow {
		&.slick-prev {
			left: 50%;
			margin-left: -570px;
		}
		&.slick-next {
			right: 50%;
			margin-right: -570px;
		}
	}
	@media screen and (max-width: 1280px) {
		.slick-slide {
			height: auto;
			.slider__block {
				font-size: 22px;
				.slider__block--title {
					font-size: 42px;
				}
				.btn {
					margin-top: 30px;
				}
			}
		}
		&.arrow {
			.slick-arrow {
				&.slick-prev {
					left: 15px;
					margin: 0;
				}
				&.slick-next {
					right: 15px;
					margin: 0;
				}
			}
		}
	}
	@media screen and (max-width: 1199px) {
		.slick-slide {
			.slider__block {
				width: auto;
				font-size: 20px;
				padding: 0 50px;
				.slider__block--title {
					font-size: 36px;
				}
			}
		}
	}
	@media screen and (max-width: 991px) {
		.slick-slide {
			.slider__block {
				font-size: 18px;
				.slider__block--title {
					font-size: 28px;
				}
				.btn {
					margin-top: 20px;
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		.slick-slide {
			.slider__block {
				line-height: 1.2;
				.slider__block--title {
					display: block;
					font-size: 24px;
					margin-bottom: 5px;
				}
			}
		}
	}
	@media screen and (max-width: 600px) {
		.slick-slide {
			height: 260px;
			.slider__block {
				font-size: 16px;
				padding: 0 35px;
				.slider__block--title {
					font-size: 20px;
				}
			}
		}
		&.arrow {
			.slick-arrow {
				&.slick-prev {
					left: 10px;
				}
				&.slick-next {
					right: 10px;
				}
			}
		}
	}
}
.arrow {
	.slick-arrow {
		width: 23px;
		height: 42px;
		z-index: 1;
		transition: 300ms;
		&:before {
			display: none;
		}
		&:after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background: url(../img/next.png) no-repeat center center;
			background-size: contain;
		}
		&.slick-prev {
			left: 0;
			&:after {
				transform: rotate(-180deg);
			}
		}
		&.slick-next {
			right: 0;
		}
		&:hover {
			opacity: 0.7;
		}
	}
	@media screen and (max-width: 991px) {
		.slick-arrow {
			width: 18px;
			height: 30px;
		}
	}
}
/* main slider */

/* main advantages */
.advantages__block {
	display: flex;
	max-width: 1170px;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 70px 15px;
	margin: 0 auto;
	.col {
		max-width: 220px;
		text-align: center;
		padding: 0 25px;
		.icon {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 67px;
			height: 67px;
			border-radius: 3px;
			background-color: #e78000;
			padding: 5px;
			margin: 0 auto 25px;
			img {
				max-width: 100%;
				max-height: 100%;
				object-fit: contain;
				object-position: center center;
			}
		}
		h5 {
			text-transform: inherit;
			line-height: 1.3;
			margin: 0;
		}
	}
	@media screen and (max-width: 1199px) {
		.col {
			padding: 0 15px;
			h5 {
				font-size: 16px;
			}
		}
	}
	@media screen and (max-width: 991px) {
		justify-content: center;
		padding: 50px 15px;
		.col {
			max-width: initial;
			width: 33.33%;
			margin: 15px 0;
		}
	}
	@media screen and (max-width: 768px) {
		padding: 30px 15px;
	}
	@media screen and (max-width: 520px) {
		.col {
			width: 50%;
		}
	}
	@media screen and (max-width: 420px) {
		.col {
			width: 100%;
			padding: 0;
			.icon {
				margin-bottom: 10px;
			}
		}
	}
}
/* main advantages */

/* main catalog */
.cat {
	padding-bottom: 70px;
	.container {
		padding-bottom: 0;
	}
	@media screen and (max-width: 1280px) {
		padding-bottom: 50px;
	}
	@media screen and (max-width: 1199px) {
		padding-bottom: 30px;
	}
	@media screen and (max-width: 991px) {
		padding-bottom: 20px;
	}
}
.cat__block {
	display: flex;
	flex-wrap: wrap;
	padding: 0 15px;
	.cat__col {
		width: calc(33.33% - 30px);
		height: 350px;
		overflow: hidden;
		border-radius: 3px;
		position: relative;
		margin: 15px;
		&:before {
			content: '';
			background-color: #282828;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0.5;
			transition: 300ms;
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}
		h3 {
			color: #fff;
			text-align: center;
			position: absolute;
			top: 50%;
			left: 15px;
			right: 15px;
			transform: translateY(-50%);
			margin: 0;
		}
		.more {
			display: flex;
			align-items: center;
			justify-content: center;
			min-height: 70px;
			color: #fff;
			text-transform: uppercase;
			position: absolute;
			left: 0;
			right: 0;
			bottom: -100%;
			padding: 10px 15px;
			transition: 500ms;
			opacity: 0;
		}
		&:hover {
			&:before {
				opacity: 0.8;
			}
			.more {
				opacity: 1;
				bottom: 0;
				transition: 300ms;
			}
		}
	}
	@media screen and (max-width: 1440px) {
		.cat__col {
			height: 280px;
			h3 {
				font-size: 20px;
			}
		}
	}
	@media screen and (max-width: 1280px) {
		.cat__col {
			height: 240px;
			.more {
				min-height: 50px;
				bottom: 0;
				opacity: 1;
			}
		}
	}
	@media screen and (max-width: 991px) {
		.cat__col {
			height: 220px;
			h3 {
				font-size: 18px;
			}
		}
	}
	@media screen and (max-width: 768px) {
		padding: 0 10px;
		.cat__col {
			width: calc(50% - 20px);
			margin: 10px 10px;
			.more {
				min-height: 40px;
			}
		}
	}
	@media screen and (max-width: 480px) {
		padding: 0 15px;
		.cat__col {
			width: 100%;
			margin: 0 0 20px;
		}
	}
}
/* main catalog */

/* main about */
.about__block {
	display: flex;
	align-items: stretch;
	.left {
		width: 45%;
		min-height: 600px;
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}
	}
	.right {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 665px;
		padding: 25px 25px 25px 125px;
	}
	.btn {
		width: 200px!important;
		margin-top: 75px;
	}
	@media screen and (max-width: 1440px) {
		.left {
			min-height: 0px;
		}
		.right {
			padding-left: 100px;
			padding-top: 40px;
			padding-bottom: 40px;
		}
		.btn {
			margin-top: 35px;
		}
		h1 {
			margin-bottom: 40px;
		}
	}
	@media screen and (max-width: 1280px) {
		.right {
			padding: 25px 15px 25px 25px;
		}
		h1 {
			margin-bottom: 25px;
		}
		.btn {
			margin-top: 20px;
		}
	}
	@media screen and (max-width: 991px) {
		flex-direction: column;
		h1 {
			margin-bottom: 15px;
		}
		.left {
			width: 100%;
			order: 2;
		}
		.right {
			min-height: 0px;
			width: 100%;
			order: 1;
			img {
				height: auto;
			}
		}
		.btn {
			margin-top: 10px;
		}
	}
}
/* main about */

/* main partners */
.partners {
	.container {
		padding: 65px 15px;
	}
	@media screen and (max-width: 1280px) {
		.container {
			padding: 30px 15px;
		}
	}
	@media screen and (max-width: 768px) {
		.container {
			padding: 15px;
		}
	}
}
.partners__slider {
	.slick-slide {
		display: flex;
		height: 200px;
		align-items: center;
		outline: none;
		position: relative;
		padding: 15px 65px;
		/* border-right: 1px solid #d5d5d9; */			
		img {
			display: block;
			max-width: 100%;
			max-height: 100%;
			object-fit: contain;
			object-position: center center;
			filter: grayscale(100%);
			transition: 300ms;
		}
		&:hover img {
			filter: grayscale(0);
		}
	}
	&.arrow {
		.slick-arrow:after {
			background: url(../img/next1.png) no-repeat center center;
			background-size: contain;
		}
	}
	@media screen and (max-width: 1280px) {
		.slick-slide {
			height: 175px;
			padding: 15px 40px;
		}
	}
	@media screen and (max-width: 991px) {
		padding: 0 25px;
		.slick-slide {
			height: 150px;
			padding: 0px 15px;
			img {
				filter: grayscale(0);
			}
		}
	}
	@media screen and (max-width: 480px) {
		.slick-slide {
			height: 120px;
		}
	}
}
/* main partners */

/* main news */
.news {
    .container {
        padding-top: 0;
    }
}
.news__block {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	.news__block--col {
		display: flex;
		flex-direction: column;
		width: calc(50% - 30px);
		color: #292929;
		.news__block--img {
			height: 280px;
			border-radius: 3px 3px 0 0;
			overflow: hidden;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
				transition: 300ms;
			}
		}
		.news__block--info {
			flex: 1;
			background-color: #f4f5f9;
			padding: 40px 30px;
			position: relative;
			transition: 300ms;
			&:after {
				content: '';
			    background: linear-gradient(to left, #e87e00 10%, #db0000, #140001 90%);
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				opacity: 0;
				transition: 300ms;
			}
			.date {
				display: block;
				color: #525253;
				margin-bottom: 25px;
				transition: 300ms;
			}
			h5 {
				min-height: 38px;
				margin: 0 0 10px;
				transition: 300ms;
			}
			p {
				margin-bottom: 0;
			}
			* {
				position: relative;
				z-index: 1;
			}
		} 
		&:hover {
			.news__block--img {
				img {
					transform: scale(1.1);
				}
			}
			.news__block--info {
				color: #fff;
				&:after {
					opacity: 1;
				}
				.date {
					color: #fff;
				}
				h5 {
					color: #fff;
				}
			}
		}
	}
	@media screen and (max-width: 1280px) {
		.news__block--col {
			width: calc(50% - 15px);
			.news__block--info {
				padding: 20px 25px;
				.date {
					margin-bottom: 15px;
				}
			}
		}
	}
	@media screen and (max-width: 1199px) {
		.news__block--col {
			.news__block--img {
				height: 240px;
			}
			.news__block--info {
				.date {
					margin-bottom: 10px;
				}
			}
		}
	}
	@media screen and (max-width: 991px) {
		.news__block--col {
			.news__block--img {
				height: 200px;
			}
		}
	}
	@media screen and (max-width: 768px) {
		.news__block--col {
			.news__block--info {
				color: #fff;
				&:after {
					opacity: 1;
				}
				.date, h5 {
					color: #fff;
				}
			}
		}
	}
	@media screen and (max-width: 600px) {
		flex-wrap: wrap;
		.news__block--col {
			width: 100%;
			margin-bottom: 20px;
			.news__block--img {
				height: auto;
			}
			.news__block--info {
				padding: 15px 20px;
				h5 {
					min-height: 0px;
				}
			}
		}
	}
}
/* main news */

/* main content */