/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

/** Generated by FG **/
@font-face {
	font-family: 'Conv_ProximaNova-Medium';
	src: url('../fonts/ProximaNova-Medium.eot');
	src: local('☺'), url('../fonts/ProximaNova-Medium.woff') format('woff'), 
		 url('../fonts/ProximaNova-Medium.ttf') format('truetype'), 
		 url('../fonts/ProximaNova-Medium.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
    font-family: 'ProximaNovaItalic';
    src: url('../fonts/ProximaNovaItalic.eot');
    src: url('../fonts/ProximaNovaItalic.eot') format('embedded-opentype'),
         url('../fonts/ProximaNovaItalic.woff2') format('woff2'),
         url('../fonts/ProximaNovaItalic.woff') format('woff'),
         url('../fonts/ProximaNovaItalic.ttf') format('truetype'),
         url('../fonts/ProximaNovaItalic.svg#ProximaNovaItalic') format('svg');
}

@font-face {
    font-family: 'ProximaNovaRegular';
    src: url('../fonts/ProximaNovaRegular.eot');
    src: url('../fonts/ProximaNovaRegular.eot') format('embedded-opentype'),
         url('../fonts/ProximaNovaRegular.woff2') format('woff2'),
         url('../fonts/ProximaNovaRegular.woff') format('woff'),
         url('../fonts/ProximaNovaRegular.ttf') format('truetype'),
         url('../fonts/ProximaNovaRegular.svg#ProximaNovaRegular') format('svg');
}
@font-face {
    font-family: 'ProximaNovaBlack';
    src: url('../fonts/ProximaNovaBlack.eot');
    src: url('../fonts/ProximaNovaBlack.eot') format('embedded-opentype'),
         url('../fonts/ProximaNovaBlack.woff2') format('woff2'),
         url('../fonts/ProximaNovaBlack.woff') format('woff'),
         url('../fonts/ProximaNovaBlack.ttf') format('truetype'),
         url('../fonts/ProximaNovaBlack.svg#ProximaNovaBlack') format('svg');
}
@font-face {
    font-family: 'ProximaNovaBold';
    src: url('../fonts/ProximaNovaBold.eot');
    src: url('../fonts/ProximaNovaBold.eot') format('embedded-opentype'),
         url('../fonts/ProximaNovaBold.woff2') format('woff2'),
         url('../fonts/ProximaNovaBold.woff') format('woff'),
         url('../fonts/ProximaNovaBold.ttf') format('truetype'),
         url('../fonts/ProximaNovaBold.svg#ProximaNovaBold') format('svg');
}
@font-face {
    font-family: 'ProximaNovaLight';
    src: url('../fonts/ProximaNovaLight.eot');
    src: url('../fonts/ProximaNovaLight.eot') format('embedded-opentype'),
         url('../fonts/ProximaNovaLight.woff2') format('woff2'),
         url('../fonts/ProximaNovaLight.woff') format('woff'),
         url('../fonts/ProximaNovaLight.ttf') format('truetype'),
         url('../fonts/ProximaNovaLight.svg#ProximaNovaLight') format('svg');
}

$regular: 'ProximaNovaRegular';
$bold: 'ProximaNovaBold';
$black: 'ProximaNovaBlack';
$light: 'ProximaNovaLight';
$italic: 'ProximaNovaItalic';
$medium: 'Conv_ProximaNova-Medium';

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}

*:after, *:before {
	display: block;
	line-height: 1;
}

body {
	display: flex;
	height: 100vh;
	min-width: 300px;
	position: relative;
	flex-direction: column;
	font-family: $regular;
	padding-top: 135px;
	margin: 0;
    &.load {
        a {
            transition: 300ms;
        }
    }
	@media screen and (max-width: 991px) {
		padding-top: 80px;
	}
}

h1 {
	position: relative;
	font-family: $bold;
	font-size: 38px;
	color: #292929;
	text-transform: uppercase;
	line-height: 1;
	padding-bottom: 25px;
	margin: 0 0 60px;
	&:after {
    	content: '';
    	width: 67px;
    	height: 2px;
    	background-color: #e78000;
    	position: absolute;
    	left: 0;
    	bottom: 0;
    }
    a {
        font-size: 38px;
    }
	@media screen and (max-width: 1199px) {
		font-size: 32px;
		margin-bottom: 40px;
        a {
            font-size: 32px;
        }
	}
	@media screen and (max-width: 991px) {
		font-size: 28px;
		padding-bottom: 20px;
        margin-bottom: 30px;
        a {
            font-size: 28px
        }
	}
    @media screen and (max-width: 768px) {
        font-size: 24px;
		padding-bottom: 10px;
        margin-bottom: 20px;
        a {
            font-size: 24px;
        }
    }
}
h2 {
	font-family: $bold;
	font-size: 36px;
	color: #292929;
	text-transform: uppercase;
	position: relative;
	padding-bottom: 25px;
    margin: 0 0 60px;
    &:after {
    	content: '';
    	width: 67px;
    	height: 2px;
    	background-color: #e78000;
    	position: absolute;
    	left: 0;
    	bottom: 0;
    }
	a {
        font-size: 36px;
		color: #282727;
	}
	@media screen and (max-width: 1199px) {
		font-size: 30px;
		margin-bottom: 40px;
		a {
			font-size: 30px;
		}
	}
	@media screen and (max-width: 991px) {
		font-size: 24px;
		padding-bottom: 20px;
        margin-bottom: 30px;
		a {
			font-size: 24px;
		}
	}
    @media screen and (max-width: 768px) {
        font-size: 22px;
		padding-bottom: 10px;
        margin-bottom: 20px;
        a {
        	font-size: 22px;
        }
    }
}
h3 {
	font-family: $bold;
	font-size: 24px;
	color: #292929;
	text-transform: uppercase;
	@media screen and (max-width: 991px) {
		font-size: 22px;
	}
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
}
h4 {
	font-family: $bold;
	font-size: 22px;
	color: #292929;
	text-transform: uppercase;
    @media screen and (max-width: 991px) {
        font-size: 20px;
    }
    @media screen and (max-width: 768px) {
        font-size: 18px;
    }
}
h5 {
	font-family: $bold;
	font-size: 18px;
	color: #292929;
}
h6 {
	font-family: $bold;
	font-size: 16px;
	text-transform: uppercase;
	color: #292929;
}
a {
    color: #292929;
	text-decoration: none;
	&:focus {
		color: #292929;
		text-decoration: none;
	}
	&:hover {
		color: #e78000;
		text-decoration: none;
	}
}
textarea, 
button, 
select, 
input[type="text"], 
input[type="email"], 
input[type="search"],
input[type="password"] {
	border-radius: 0px;
	-webkit-appearance: none;
}
.main, .page {
	position: relative;
	flex: 1 0 auto;
	font-size: 16px;
	textarea, 
    input[type="text"], 
    input[type="email"], 
    input[type="search"], 
    input[type="password"] {
        width: 100%;
        min-height: 50px;
        background-color: #fff;
        font-family: $regular;
        font-size: 16px;
        padding: 13px 20px;
        margin-bottom: 20px;
        border-radius: 2px;
        border: 1px solid #dddddd;
        box-shadow: none;
        &::placeholder {
            color: #7e7e7e;
        }
    }
	@media screen and (max-width: 991px) {
		textarea, 
	    input[type="text"], 
	    input[type="email"], 
	    input[type="search"], 
	    input[type="password"] {
	    	min-height: 40px;
	    	padding: 8px 15px;
	    	margin-bottom: 15px;
	    }
	}
}
.main {
	.container {
		padding-top: 100px;
		padding-bottom: 100px;
		@media screen and (max-width: 1199px) {
			padding-top: 50px;
			padding-bottom: 50px;
		}
		@media screen and (max-width: 991px) {
			padding-top: 30px;
			padding-bottom: 30px;
		}
	}
}
.close {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 8px;
    top: 10px;
    opacity: 1;
    outline: none;
    &:before, &:after {
        content: '';
        width: 25px;
        height: 2px;
        background-color: #e78000;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: -1px auto 0;
        transition: 300ms;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
    &:hover {
        &:before {
            transform: rotate(135deg);
        }
        &:after {
            transform: rotate(45deg);
        }
    }
}
.btn {
	display: table;
	height: 50px;
	width: auto!important;
	background-color: #e78000!important;
	font-size: 16px;
	font-weight: normal;
	color: #fff!important;
	text-transform: uppercase;
	cursor: pointer;
	border-radius: 0px;
	border: 2px solid #e78000;
	padding: 14px 30px!important;
	border-radius: 3px;
	transition: 300ms;
	line-height: 1;
	&:focus, &:active {
		background-color: #e78000!important;
		color: #fff;
	}
	&:hover, &.active {
		background-color: #fff!important;
		color: #e78000!important;
	}	
	@media screen and (max-width: 991px) {
		height: 40px;
		padding: 10px 25px!important;
	}
}

.square--list {
	@include ul-default;
	li {
		display: block;
		position: relative;
		padding-left: 17px;
		margin-bottom: 5px;
		&:before {
			content: '';
			width: 6px;
			height: 6px;
			background-color: #e78000;
			position: absolute;
			left: 0;
			top: 7px;
		}
	}
}

.col--location,
.col--phone,
.col--email {
	position: relative;
	padding-left: 25px;
	font-size: 16px;
	&:after {
		content: '';
		font-family: 'fontAwesome';
		font-size: 16px;
		color: #e78000;
		position: absolute;
		top: 1px;
		left: 0;
	}
}
.col--location:after {
	content: '\f041';
}
.col--phone:after {
	content: '\f095';
	top: 3px;
}
.col--email {
	&:after {
		content: '\f0e0';
		font-size: 14px;
		top: 3px;
	}
	a {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
}

.grd {
	background: linear-gradient(to left, #e87e00 10%, #db0000, #140001 90%);
}
.gray {
	background-color: #f4f5f9;
}

.line--title {
	position: relative;
	padding-bottom: 18px;
	&:after {
    	content: '';
    	width: 67px;
    	height: 2px;
    	background-color: #e78000;
    	position: absolute;
    	left: 0;
    	bottom: 0;
    }
    @media screen and (max-width: 991px) {
    	padding-bottom: 10px;
    }
}

/* header */
header {
	height: 135px;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 9;

	ul {
		@include ul-default;
	}

	/* header logo */
	.logo {
		@include inline-block;
		margin: 20px 0 0 0;
		img {
			display: block;
		}
	}
	/* header logo */

	.header--info {
		display: inline-flex;
		width: 845px;
		height: 95px;
		align-items: center;
		color: #fff;
		p {
			margin: 0;
		}
		a {
            white-space: nowrap;
			color: #fff;
		}
		.col {
			margin-left: 35px;
		}
	}

	.down {
		background-color: #fff;
		border-radius: 3px;
		box-shadow: 0px 5px 10px rgba(0,0,0,0.1);
	}

	/* header nav */
	.nav {
		max-width: 900px;
		margin-left: 22px;
		> ul {
			display: flex;
			justify-content: space-between;
			> li {
				position: relative;
				> a {
					display: flex;
					height: 80px;
					align-items: center;
					font-family: $medium;
					font-size: 18px;
					color: #292929;
					text-transform: uppercase;
					padding: 10px 17px 10px 10px;
					&:hover {
						color: #e78000;
					}
				}
				&.active > a {
					color: #e78000;
				}
				&.submenu {
					> a {
						&:after {
							content: '\f107';
							font-family: 'fontAwesome';
							font-size: 18px;
							color: #292929;
							position: absolute;
							right: 0;
							top: 49%;
							transform: translateY(-50%);
							transition: 400ms;
						}
						&:hover:after {
							transform: translateY(-50%) rotate(-180deg);
							color: #e78000;
						}
					}
				}
				ul {
					display: none;
					width: 420px;
					background-color: #575757;
					position: absolute;
					top: 100%;
					left: -40px;
					padding: 40px 20px 40px 48px;
					li {
						margin: 7.5px 0;
						a {
							font-size: 16px;
							color: #fff;
							text-transform: uppercase;
							&:hover {
								color: #e78000;
							}
						}
						&.active a {
							color: #e78000;
						}
					}
				}
			}
		}
	}
	/* header nav */

	/* header search */
	.search {
		display: flex;
		width: 35px;
		flex-wrap: nowrap;
		position: absolute;
		top: 50%;
		right: 145px;
		overflow: hidden;
		transform: translateY(-50%);
		transition: 300ms;
		border: 1px solid transparent;
		padding-right: 35px;
		z-index: 1;
		input {
			width: 100%;
			border: none;
			padding: 10px 15px;
			outline: none;
			transition: 300ms;
		}
		button {
			background-color: transparent;
			font-family: 'fontAwesome';
			font-size: 20px;
			color: #292929;
			padding: 5px;
			outline: none;
			border: none;
			position: absolute;
			right: 8px;
			top: 50%;
			transform: translateY(-50%);
			&:after {
				content: '\f002';
			}
		}
		&:hover {
			width: 400px;
			border: 1px solid #e78000;
		}
	}
	/* header search */

	/* header langs */
	.langs {
		position: absolute;
		padding-right: 17px;
		right: 27px;
		top: 50%;
		transform: translateY(-50%);
		&:after {
			content: '\f107';
			font-family: 'fontAwesome';
			font-size: 18px;
			color: #292929;
			position: absolute;
			right: 0;
			top: 45%;
			transform: translateY(-50%);
		}
		.langs__title {
			font-family: $medium;
			font-size: 18px;
			color: #292929;
			text-transform: uppercase;
			cursor: pointer;
		}
		ul {
			display: flex;
			width: 45px;
			height: 0;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background-color: rgba(41, 41, 41, 0.8);
			padding: 0 10px;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			border-radius: 3px;
			overflow: hidden;
			transition: 200ms;
			line-height: 1;
			li {
				padding: 3px 0;
				margin: 0;
				a {
					display: block;
					font-family: $medium;
					font-size: 16px;
					color: #fff;
					text-transform: uppercase;
					&:hover {
						color: #e78000;
					}
				}
				&.active a {
					color: #e78000;
				}
			}
		}
		&.open {
			ul {
				height: 80px;
				padding: 10px 10px;
			}
		}
	}
	/* header langs */
	
	@media screen and (min-width: 992px) {
		.down {
			position: relative;
		}
	}
	@media screen and (max-width: 1199px) {
		.header--info {
			width: 700px;
			justify-content: flex-end;
			.col {
				margin-left: 50px;
			}
		}
		.nav {
			max-width: 785px;
			margin: 0 0 0 15px;
			> ul > li {
				ul {
					width: 300px;
					padding: 15px 20px 15px 20px;
					left: 0;
				}
			}
		}
		.search {
			right: 80px;
		}
	}
	@media screen and (max-width: 991px) {
		height: 80px;
		.logo {
			width: 150px;
			display: table;
			margin: 20px auto 0;
			img {
				width: 100%;
			}
		}
		.header--info {
			display: none;
			max-width: 100%;
			width: auto;
			height: auto;
			background: linear-gradient(to left,#e87e00 10%,#db0000,#140001 90%);
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			padding: 15px;
			z-index: 2;
			.col {
				display: table;
				margin: 5px auto;
			}
		}
		.nav {
			display: none;
			max-width: initial;
			max-height: calc(100% - 80px);
			background-color: #fff;
			position: fixed;
			top: 80px;
			left: 0;
			right: 0;
			padding: 15px;
			margin: 0;
			overflow: auto;
			box-shadow: 0px 0px 15px rgba(0,0,0,0.5);
			z-index: 1;
			> ul {
				display: block;
				> li {
					> a {
						display: table;
						height: auto;
						position: relative;
						font-size: 14px;
						padding: 5px 17px 5px 0;
					}
					ul {
						background-color: transparent;
						width: 100%;
						position: relative;
						padding: 5px 10px;
						top: 0;
						border-top: 1px solid #e78000;
						border-bottom: 1px solid #e78000;
						li {
							a {
								font-size: 14px;
								color: #292929;
							}
						}
					}
				}
			}
		}
		.nav--btn {
			width: 35px;
			height: 30px;
			position: absolute;
			left: 12px;
			top: 50%;
			padding: 0;
			margin: 0;
			outline: none;
			float: none;
			transform: translateY(-50%);
			span {
				position: absolute;
				left: 0px;
				right: 0;
				width: 85%;
				height: 3px;
				margin: 0 auto;
				background-color: #fff;
				transition: 350ms;
				&:nth-of-type(1) {
					top: 7px;
				}
				&:nth-of-type(2) {
					top: 14px;
				}
				&:nth-of-type(3) {
					top: 21px;
				}
			}
			&.open {
				span {
					&:nth-of-type(1) {
						top: 14px;
						transform: rotate(45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						top: 14px;
						transform: rotate(-45deg);
					}
				}
			}
		}
		.search {
			display: none;
			left: 0;
			right: 0;
			width: auto;
			background-color: #fff;
			border: 2px solid #e78000;
			transition: 0ms;
			&:hover {
				width: auto;
				border: 2px solid #e78000;
			}
		}
		.search--btn {
			display: block;
			font-family: 'fontAwesome';
			font-size: 22px;
			color: #fff;
			position: absolute;
			right: 15px;
			top: 50%;
			transform: translateY(-50%);
			&:after {
				content: '\f002';
			}
			&:hover {
				text-decoration: none;
			}
		}
		.phones--btn {
			display: block;
			font-family: 'fontAwesome';
			font-size: 24px;
			color: #fff;
			position: absolute;
			right: 55px;
			top: 50%;
			transform: translateY(-50%);
			&:after {
				content: '\f095';
			}
			&:hover {
				text-decoration: none;
			}
		}
		.langs {
			right: auto;
			left: 60px;
			&:after {
				color: #fff;
			}
			.langs__title {
				color: #fff;
			}
			ul {
				z-index: 1;
			}
			&.open {
				ul {
					padding: 5px 10px;
				}
			}
		}
	}
	@media screen and (max-width: 480px) {
		.logo {
			width: 140px;
			margin-top: 25px;
		}
		.nav--btn {
			left: 5px;
		}
		.langs {
			left: 45px;
			&:after {
				font-size: 16px;
				top: 40%;
			}
			.langs__title {
				font-size: 16px;
			}
			ul li a {
				font-size: 14px;
			}
			&.open {
				ul {
					height: 70px;
				}
			}
		}
		.search--btn {
			font-size: 20px;
			right: 10px;
		}
		.phones--btn {
			font-size: 20px;
			right: 45px;
		}
	}
}
/* header */