/* page content */
.page {
    padding-bottom: 80px;
	.news1, .news {
		.date {
			color: #e78000;
		}
	}
	.page_nav {
		.active {
			color: #e78000;
		}
	}
    #ask-form {
        max-width: 350px;
    }
    .write {
        color: #e78000;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .back_link {
        margin: 25px 0;
    }
    .search--active {
        font-family: $bold!important;
        color: #e78000!important;
    }
    .back {
        display: table;
        color: #e78000;
        text-decoration: underline;
        margin: 15px 0;
        &:hover {
            text-decoration: none;
        }
    }
	@media screen and (max-width: 1170px) {
		font-size: 16px;
		#photos-list td img {
			width: 100%;
			height: auto;
		}
	}
    @media screen and (max-width: 991px) {
        padding-bottom: 50px;
    }
    @media screen and (max-width: 768px) {
        padding-bottom: 30px;
    }
	@media screen and (max-width: 480px) {
		p img, p strong img {
			width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
		}
		#photos-list td {
			width: 50%;
			@include inline-block;
		}
	}
}
/* breadcrumbs */
.breadcrumbs {
    background-color: #f4f5f9;
    font-family: $medium;
	font-size: 16px;
    padding-top: 58px;
    padding-bottom: 15px;
    margin-bottom: 75px;
	ul {
		@include ul-default;
		li {
            @include inline-block;
			padding: 5px 0px;
			color: #e78000;
			a {
                font-size: 16px;
				text-decoration: none;
                color: #292929;
                &:hover {
                    color: #e78000;
                }
			}
			&:after {
				content: '';
                @include inline-block;
                height: 16px;
                width: 2px;
                background-color: #292929;
				margin: 2px 5px 0px 7px;
				color: #e78000;
			}
			&:last-of-type:after {
				display: none;
			}
		}
	}
    @media screen and (max-width: 991px) {
        font-size: 14px;
        padding: 15px 0;
        margin-bottom: 50px;
        ul li {
            &:after {
                margin-top: 0px;
            }
            a {
                font-size: 14px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        padding: 5px 0;
        margin-bottom: 30px;
    }
}
/* breadcrumbs */

/* page about */
.page {
    .about__block {
        align-items: stretch;
        position: relative;
        padding-bottom: 100px;
        &:after {
            content: '';
            height: 485px;
            background-color: #f4f5f9;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
        }
        .left {
            overflow: hidden;
            border-radius: 0px 10px 10px 0px;
        }
        .right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-top: 0;
            padding-bottom: 0;
        }
        .advantages__block {
            justify-content: center;
            padding: 0;
            margin-left: -125px;
            margin-top: 25px;
            .col {
                width: 33.33%;
                margin: 50px 0 0 0;
            }
        }
    }
    .partners {
        margin-bottom: -80px;
    }
    @media screen and (max-width: 1280px) {
        .about__block {
            .advantages__block {
                margin-left: -15px;
            }
        }
    }
    @media screen and (max-width: 1199px) {
        .about__block {
            .advantages__block {
                margin-left: -50px;
            }
        }
    }
    @media screen and (max-width: 991px) {
        .about__block {
            width: 750px;
            padding-bottom: 0;
            margin: 0 auto;
            &:after {
                display: none;
            }
            .advantages__block {
                margin-left: 0;
                margin-top: 0;
                margin-bottom: 25px;
                .col {
                    margin: 25px 0;
                }
            }
            .left {
                border-radius: 0px;
            }
            .right {
                padding: 0 15px;
            }
        }
        .partners {
            margin-bottom: -50px;
        }
    }
    @media screen and (max-width: 768px) {
        .partners {
            margin-bottom: -30px;
        }
    }
    @media screen and (max-width: 767px) {
        .about__block {
            width: 100%;
        }
    }
    @media screen and (max-width: 520px) {
        .about__block {
            .advantages__block {
                .col {
                    width: 50%;
                }
            }
        }
    }
    @media screen and (max-width: 420px) {
        .about__block {
            .advantages__block .col {
                width: 100%;
                margin: 15px 0;
            }
        }
    }
}
/* page about */

/* page catalog */
.cat--inside {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    .cat--inside__col {
        display: flex;
        flex-direction: column;
        width: calc(25% - 30px);
        margin: 0 15px 30px;
        .cat--inside__img {
            height: 265px;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
        .cat--inside__block {
            flex: 1;
            background-color: #f4f5f9;
            padding: 30px;
            text-align: center;
            position: relative;
            &:after {
                content: '';
                background: linear-gradient(to left,#e87e00 10%,#db0000,#140001 90%);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
                transition: 300ms;
            }
            h5 {
                min-height: 38px;
                margin: 0 0 10px;
                transition: 300ms;
            }
            .price {
                color: #e78000;
                transition: 300ms;
            }
            * {
                position: relative;
                z-index: 1;
            }
        }
        &:hover {
            .cat--inside__block {
                &:after {
                    opacity: 1;
                }
                h5 {
                    color: #fff;
                }
                .price {
                    color: #fff;
                }
            }
        }
    }
    @media screen and (max-width: 1199px) {
        .cat--inside__col {
            .cat--inside__img {
                height: 230px;
            }
            .cat--inside__block {
                padding: 20px;
            }
        }
    }
    @media screen and (max-width: 991px) {
        margin: 0 -10px;
        .cat--inside__col {
            width: calc(33.33% - 20px);
            margin: 0 10px 20px;
            .cat--inside__block {
                h5 {
                    font-size: 16px;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        .cat--inside__col {
            .cat--inside__img {
                height: 200px;
            }
        }
    }
    @media screen and (max-width: 680px) {
        .cat--inside__col {
            .cat--inside__img {
                height: 175px;
            }
        }
    }
    @media screen and (max-width: 600px) {
        .cat--inside__col {
            width: calc(50% - 20px);
            .cat--inside__img {
                height: 200px;
            }
        }
    }
    @media screen and (max-width: 480px) {
        .cat--inside__col {
            .cat--inside__img {
                height: 175px;
            }
        }
    }
    @media screen and (max-width: 420px) {
        margin: 0;
        .cat--inside__col {
            width: 100%;
            margin: 0px 0px 20px;
            .cat--inside__img {
                height: auto;
                img {
                    height: auto;
                }
            }
        }
    }
}
.cat--unit {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 65px;
    .left {
        display: flex;
        width: 555px;
    }
    .right {
        width: calc(100% - 555px);
        padding-left: 30px;
    }
    .cat--unit__price {
        display: flex;
        align-items: flex-end;
        line-height: 1;
        margin: 25px 0 15px;
        p {
            margin: 0 55px 5px 0;
        }
        .price {
            font-family: $bold;
            font-size: 24px;
            color: #e78000;
        }
    }
    h1 {
        font-size: 28px;
        margin-bottom: 40px;
    }
    @media screen and (max-width: 1199px) {
        h1 {
            font-size: 24px;
            margin-bottom: 20px;
        }
    }
    @media screen and (max-width: 991px) {
        margin-bottom: 40px;
        .left {
            width: 100%;
            order: 2;
        }
        .right {
            width: 100%;
            padding: 0;
            order: 1;
            margin-bottom: 20px;
        }
    }
    @media screen and (max-width: 420px) {
        .cat--unit__price {
            flex-direction: column;
            align-items: flex-start;
            p {
                margin: 0;
            }
            .price {
                display: block;
                margin: 10px 0 0;
            }
        }
    }
}
.cat--unit__for {
    width: 455px;
    .slick-slide {
        height: 455px;
        outline: none;
        border-radius: 4px;
        overflow: hidden;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }
    @media screen and (max-width: 600px) {
        width: calc(100% - 100px);
        .slick-slide {
            height: 355px;
        }
    }
    @media screen and (max-width: 480px) {
        width: calc(100% - 95px);
        .slick-slide {
            height: 300px;
        }
    }
    @media screen and (max-width: 380px) {
        width: calc(100% - 85px);
        .slick-slide {
            height: 250px;
        }
    }
}
.cat--unit__nav {
    width: 85px;
    padding: 32px 0;
    margin-right: 15px;
    .slick-slide {
        height: 80px;
        outline: none;
        margin: 8px 0;
        border-radius: 4px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            border: 3px solid #e78000;
            border-radius: 4px;
            opacity: 0;
            transition: 300ms;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
        &:hover, &.slick-current {
            &:after {
                opacity: 1;
            }
        }
    }
    &.arrow {
        .slick-arrow {
            width: 10px;
            height: 22px;
            left: 0;
            right: 0;
            margin: 0 auto;
            &:after {
                background: url(../img/next1.png) no-repeat center center; 
                background-size: contain;
            }
            &.slick-prev {
                transform: rotate(90deg);
                top: 0;
            }
            &.slick-next {
                transform: rotate(90deg);
                top: auto;
                bottom: 0;
            }
        }
    }
    @media screen and (max-width: 480px) {
        width: 75px;
        padding: 25px 0;
        .slick-slide {
            height: 72px;
            margin: 5px 0;
        }
    }
    @media screen and (max-width: 380px) {
        width: 65px;
        .slick-slide {
            height: 65px;
            margin: 2px 0;
        }
    }
}
.cat--unit__table {
    position: relative;
    z-index: 1;
    line-height: 1.2;
    tr {
        td {
            position: relative;
            padding: 5px 25px 5px 0;
            &:first-of-type {
                min-width: 325px;
                vertical-align: bottom;
                &:after {
                    content: '';
                    height: 1px;
                    position: absolute;
                    left: 0;
                    bottom: 10px;
                    right: 25px;
                    border: 1px dotted #929292;
                    z-index: -1;
                }
            }
            strong {
                background-color: #fff;
                font-weight: normal;
                color: #7e7e7e;
            }
        }
    }
    @media screen and (max-width: 1199px) {
        tr {
            td {
                &:first-of-type {
                    min-width: 0px;
                }
            }
        }
    }
    @media screen and (max-width: 991px) {
        tr {
            td {
                padding: 5px 15px 5px 0;
                &:first-of-type {
                    &:after {
                        right: 15px;
                    }
                }
                &:last-of-type {
                    min-width: 100px;
                }
            }
        }
    }
}
.cat--unit__block {
    padding: 70px 0 35px;
    margin-bottom: -80px;
    h3 {
        position: relative;
        padding-bottom: 20px;
        margin: 0 0 40px;
        &:after {
            content: '';
            width: 67px;
            height: 2px;
            background-color: #e78000;
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }
    .cat--unit__table {
        tr {
            td {
                strong {
                    background-color: #f4f5f9;
                }
                &:nth-of-type(1) {
                    min-width: 350px;
                }
            }
        }
    }
    .cat--unit__block--info {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        .col {
            width: 50%;
            position: relative;
            padding: 0 0 30px 20px;
            &:after {
                content: '';
                width: 1px;
                background-color: #d7d8da;
                position: absolute;
                top: 100px;
                right: 0;
                bottom: 0;
            }
            .title {
                display: block;
                background-color: #e78000;
                font-family: $bold;
                font-size: 16px;
                color: #fff;
                text-transform: uppercase;
                padding: 20px;
                border-radius: 4px;
                line-height: 1;
                margin: 0 0 25px -20px;
            } 
            &:nth-child(odd) {
                .title {
                    border-radius: 4px 0px 0px 4px;
                }
            }
            &:nth-child(even) {
                &:after {
                    display: none;
                }
                .title {
                    border-radius: 0px 4px 4px 0px;
                }
            }
        }
    }
    @media screen and (max-width: 1199px) {
        h3 {
            padding-bottom: 15px;
            margin-bottom: 30px;
        }
        .cat--unit__table {
            tr {
                td {
                    &:nth-of-type(1) {
                        min-width: 0px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 991px) {
        padding-top: 40px;
        margin-bottom: -50px;
        h3 {
            padding-bottom: 10px;
            margin-bottom: 25px;
        }
        .cat--unit__block--info {
            .col {
                padding-bottom: 20px;
                &:after {
                    top: 65px;
                }
                .title {
                    padding: 15px 20px;
                    margin-bottom: 15px;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        padding-top: 30px;
        margin-bottom: -30px;
        .cat--unit__block--info {
            .col {
                width: 100%;
                &:after {
                    display: none;
                }
                .title {
                    border-radius: 4px!important;
                }
            }
        }
    }
}
/* page catalog */

/* pagination */
.pagination {
    width: 100%;
    text-align: center;
    margin: 40px 0 0 0;
    ul {
        @include ul-default;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        li {
            @include inline-block;
            margin: 0 0 5px 0;
            a {
                display: flex;
                width: 30px;
                height: 30px;
                font-family: $bold;
                font-size: 18px;
                color: #261b17;
                border-radius: 2px;
                align-items: center;
                justify-content: center;
                &:hover {
                    background-color: #e78000;
                    color: #fff;
                }
            }
            &.active a {
                background-color: #e78000;
                color: #fff;
            }
            &.prev,
            &.next {
                a {
                    position: relative;
                    background-color: transparent;
                    &:after {
                        content: '';
                        background: url(../img/next1.png) no-repeat center center;
                        background-size: 10px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                    }
                }
            }
            &.next {
                margin-left: 15px;
            }
            &.prev {
                margin-right: 15px;
                a:after {
                    transform: rotate(-180deg);
                }
            }
        }
    }
    @media screen and (max-width: 991px) {
        margin-top: 20px;
    }
}
/* pagination */

/* page contacts */
.cts {
    display: flex;
    justify-content: space-between;
    padding-right: 100px;
    ul {
        @include ul-default;
    }
    p {
        margin: 0;
    }
    .cts__col {
        padding-right: 15px;
    }
    @media screen and (max-width: 1199px) {
        padding-right: 0px;
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
        .cts__col {
            width: 100%;
            margin: 0 0 15px;
        }
    }
}
.map {
    height: 400px;
    margin: 45px 0 65px;
    > ymaps, .ymaps-2-1-72-map {
        height: 100%!important;
        width: 100%!important;
    }
    @media screen and (max-width: 1199px) {
        height: 300px;
        margin: 20px 0 35px;
    }
    @media screen and (max-width: 991px) {
        height: 250px;
    }
}
.cts__form {
    padding: 65px 0 100px;
    margin: 0 0 -80px 0;
    h3 {
        margin: 0 0 40px;
    }
    input[type="text"],
    input[type="email"],
    textarea {
        margin-bottom: 25px;
    }
    .cts__form--field {
        display: flex;
        margin: 0 -15px;
        input {
            width: calc(33.33% - 30px);
            margin-left: 15px;
            margin-right: 15px;
        }
    }
    .g-recaptcha {
        @include inline-block;
    }
    .btn {
        @include inline-block;
        margin: 25px 0 0 200px;
    }
    @media screen and (max-width: 1199px) {
        .btn {
            margin: 15px 0 0 100px;
        }
    }
    @media screen and (max-width: 991px) {
        padding: 40px 0 75px;
        margin-bottom: -50px;
        input[type="text"],
        input[type="email"],
        textarea {
            margin-bottom: 15px;
        }
        .btn {
            float: right;
            margin-left: 0;
        }
    }
    @media screen and (max-width: 768px) {
        padding: 30px 0 50px;
        margin-bottom: -30px;
        h3 {
            margin-bottom: 20px;
        }
    }
    @media screen and (max-width: 600px) {
        .cts__form--field {
            display: block;
            margin: 0;
            input {
                width: 100%;
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
    @media screen and (max-width: 480px) {
        padding-bottom: 35px;
        .g-recaptcha {
            float: right;
        }
    }
    @media screen and (max-width: 380px) {
        .g-recaptcha {
            transform: scale(0.9);
            transform-origin: 100% 50%;
        }
    }
}
/* page contacts */

/* modal */
.modal {
    .modal-dialog {
        top: 45%;
        margin: 0 auto;
        transform: translateY(-50%)!important;
    }
    .modal-content {
        width: auto;
        max-width: 420px;
        border-radius: 0px;
        margin: 0 auto;
        h4 {
            font-family: $bold;
            font-size: 18px;
            text-align: center;
            text-transform: uppercase;
            padding: 0 15px;
            margin: 0;
        }
    }
    form {
        display: block;
        padding-bottom: 140px;
        margin-top: 25px;
        textarea, 
        input[type="text"], 
        input[type="email"], 
        input[type="search"], 
        input[type="password"] {
            width: 100%;
            min-height: 50px;
            background-color: #fff;
            font-family: $regular;
            font-size: 16px;
            padding: 13px 20px;
            margin-bottom: 20px;
            border-radius: 2px;
            border: 1px solid #dddddd;
            box-shadow: none;
            &::placeholder {
                color: #7e7e7e;
            }
        }
        .g-recaptcha {
            float: right;
        }
        .btn {
            float: right;
            margin: 15px 0 0 0;
        }
    }
    @media screen and (max-width: 991px) {
        form {
            textarea, 
            input[type="text"], 
            input[type="email"], 
            input[type="search"], 
            input[type="password"] {
                min-height: 40px;
                padding: 8px 15px;
                margin-bottom: 15px;
            }
        }
    }
    @media screen and (max-width: 767px) {
        .modal-content {
            h4 {
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 420px) {
        .modal-dialog {
            margin: 0 10px;
            h4 {
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 380px) {
        .modal-content {
            form {
                .g-recaptcha {
                    transform: scale(0.85);
                    transform-origin: 100% 50%;
                }
            }
        }
    }
}


.call--btn {
    position: fixed;
    bottom: 100px;
    right: 105px;
    display: table;
    background-color: #e78000;
    font-family: 'FontAwesome';
    border-radius: 0px;
    text-decoration: none!important;
    width: 70px;
    height: 70px;
    text-align: center;
    padding: 22px 5px 5px 5px;
    box-sizing: border-box;
    border-radius: 100%;
    outline: none!important;
    z-index: 1;
    i {
        display: block;
        font-size: 28px;
        color: #fff;
        transition: 300ms;
    }
    &:after, &:before  {
        content: '';
        width: 90%;
        height: 90%;
        background-color: rgba(231, 128, 0, 0.9);
        position: absolute;
        top: 5%;
        left: 5%;
        animation-iteration-count: infinite;
        animation-duration: 1.2s;
        animation-name: ring;
        outline: none;  
        border-radius: 100%;
        z-index: -1;
    }
    &:after {
        animation-delay: 0.6s;
    }
    &:before {
        animation-delay: 0.8s;
    }
    &:hover i {
        transform: scale(1.1);
    }
    @media screen and (max-width: 1440px) {
        right: 50px;
        bottom: 50px;
    }
    @media screen and (max-width: 991px) {
        width: 50px;
        height: 50px;
        padding-top: 13px;
        right: 15px;
        i {
            font-size: 24px;
        }
    }
    @media screen and (max-width: 768px) {
        width: 40px;
        height: 40px;
        padding-top: 10px;
    }
}
@keyframes ring {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(1.7);
    }
}

/* modal */

/* page content */