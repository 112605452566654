/* footer */
footer {
	background-color: #292929;
	font-size: 14px;
	color: #fff;
	padding-top: 75px;
	ul {
		@include ul-default;
		li {
			margin: 5px 0;
			&.active a {
				color: #e78000;
			}
		}
	}
	a {
		color: #fff;
		transition: 300ms;
		&:focus {
			color: #fff;
		}
		&:hover {
			color: #e78000;
		}
	}
	.square--list {
		li {
			padding-left: 0;
			margin: 5px 0;
			&:before {
				left: -17px;
			}
		}
	}
	.col--location,
	.col--email,
	.col--phone {
		padding-left: 0;
		font-size: 14px;
		&:after {
			left: -25px;
		}
	}
	.col--phone:after {
		top: 0;
	}
	h3 {
		position: relative;
		color: #fff;
		padding-bottom: 20px;
		margin: 0 0 25px;
		&:after {
			content: '';
			width: 68px;
			height: 2px;
			background-color: #e78000;
			position: absolute;
			bottom: 0;
			left: 0;
		}
	}
	.footer__cts {
		ul li {
			margin: 5px 0 15px;
		}
	}
	.down {
		padding: 15px 0;
		margin-top: 50px;
		.container {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
			&:before, &:after {
				display: none;
			}
		}
		p {
			margin: 0;
		}
		.socials {
			li {
				@include inline-block;
				margin: 0 7.5px;
				a {
					display: flex;
					width: 30px;
					height: 30px;
					background-color: rgba(255,255,255,0.3);
					justify-content: center;
					align-items: center;
					font-size: 18px;
					color: #fff;
					border-radius: 3px;
					&:hover {
						background-color: rgba(255,255,255,0);
					}
				}
			}
		}
		.artmedia:hover {
			color: #fff;
		}
	}
	@media screen and (max-width: 1199px) {
		padding-top: 50px;
		.down {
			margin-top: 30px;
		}
		h3 {
			font-size: 20px;
			padding-bottom: 15px;
			margin-bottom: 20px;
		}
	}
	@media screen and (max-width: 991px) {
		padding-top: 30px;
		h3 {
			font-size: 18px;
			padding-bottom: 10px;
			margin-bottom: 15px;
		}
		.down {
			margin-top: 20px;
		}
	}
	@media screen and (max-width: 767px) {
		h3 {
			font-size: 16px;
		}
		.col-sm-4 {
			margin-bottom: 25px;
		}
		.square--list {
			li {
				padding-left: 17px;
				&:before {
					left: 0;
				}
			}
		}
		.col--location,
		.col--email,
		.col--phone {
			&:after {
				left: 0;
			}
		}
		.footer__cts {
			ul li {
				padding-left: 27px;
			}
		}
		.down {
			margin-top: 10px;
		}
	}
	@media screen and (max-width: 720px) {
		.down {
			text-align: center;
			.container {
				justify-content: center;
				flex-direction: column;
			}
			p {
				order: 2;
				margin: 10px 0;
			}
		}
		.socials {
			order: 1;
			margin: 0 0 5px;
		}
		.artmedia {
			order: 3;
		}
	}
}
/* footer */