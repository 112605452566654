@charset "UTF-8";
/* Connection Font Icons */
@font-face {
  font-family: 'FontAwesome';
  src: url("../fonts/fontawesome-webfont.eot");
  src: url("../fonts/fontawesome-webfont.eot") format("embedded-opentype"), url("../fonts/fontawesome-webfont.woff2") format("woff2"), url("../fonts/fontawesome-webfont.woff") format("woff"), url("../fonts/fontawesome-webfont.ttf") format("truetype"), url("../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-weight: 400;
  font-style: normal; }

/* Connection Font Icons */
/** Generated by FG **/
@font-face {
  font-family: 'Conv_ProximaNova-Medium';
  src: url("../fonts/ProximaNova-Medium.eot");
  src: local("☺"), url("../fonts/ProximaNova-Medium.woff") format("woff"), url("../fonts/ProximaNova-Medium.ttf") format("truetype"), url("../fonts/ProximaNova-Medium.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'ProximaNovaItalic';
  src: url("../fonts/ProximaNovaItalic.eot");
  src: url("../fonts/ProximaNovaItalic.eot") format("embedded-opentype"), url("../fonts/ProximaNovaItalic.woff2") format("woff2"), url("../fonts/ProximaNovaItalic.woff") format("woff"), url("../fonts/ProximaNovaItalic.ttf") format("truetype"), url("../fonts/ProximaNovaItalic.svg#ProximaNovaItalic") format("svg"); }

@font-face {
  font-family: 'ProximaNovaRegular';
  src: url("../fonts/ProximaNovaRegular.eot");
  src: url("../fonts/ProximaNovaRegular.eot") format("embedded-opentype"), url("../fonts/ProximaNovaRegular.woff2") format("woff2"), url("../fonts/ProximaNovaRegular.woff") format("woff"), url("../fonts/ProximaNovaRegular.ttf") format("truetype"), url("../fonts/ProximaNovaRegular.svg#ProximaNovaRegular") format("svg"); }

@font-face {
  font-family: 'ProximaNovaBlack';
  src: url("../fonts/ProximaNovaBlack.eot");
  src: url("../fonts/ProximaNovaBlack.eot") format("embedded-opentype"), url("../fonts/ProximaNovaBlack.woff2") format("woff2"), url("../fonts/ProximaNovaBlack.woff") format("woff"), url("../fonts/ProximaNovaBlack.ttf") format("truetype"), url("../fonts/ProximaNovaBlack.svg#ProximaNovaBlack") format("svg"); }

@font-face {
  font-family: 'ProximaNovaBold';
  src: url("../fonts/ProximaNovaBold.eot");
  src: url("../fonts/ProximaNovaBold.eot") format("embedded-opentype"), url("../fonts/ProximaNovaBold.woff2") format("woff2"), url("../fonts/ProximaNovaBold.woff") format("woff"), url("../fonts/ProximaNovaBold.ttf") format("truetype"), url("../fonts/ProximaNovaBold.svg#ProximaNovaBold") format("svg"); }

@font-face {
  font-family: 'ProximaNovaLight';
  src: url("../fonts/ProximaNovaLight.eot");
  src: url("../fonts/ProximaNovaLight.eot") format("embedded-opentype"), url("../fonts/ProximaNovaLight.woff2") format("woff2"), url("../fonts/ProximaNovaLight.woff") format("woff"), url("../fonts/ProximaNovaLight.ttf") format("truetype"), url("../fonts/ProximaNovaLight.svg#ProximaNovaLight") format("svg"); }

*:after, *:before {
  display: block;
  line-height: 1; }

body {
  display: flex;
  height: 100vh;
  min-width: 300px;
  position: relative;
  flex-direction: column;
  font-family: "ProximaNovaRegular";
  padding-top: 135px;
  margin: 0; }
  body.load a {
    transition: 300ms; }
  @media screen and (max-width: 991px) {
    body {
      padding-top: 80px; } }

h1 {
  position: relative;
  font-family: "ProximaNovaBold";
  font-size: 38px;
  color: #292929;
  text-transform: uppercase;
  line-height: 1;
  padding-bottom: 25px;
  margin: 0 0 60px; }
  h1:after {
    content: '';
    width: 67px;
    height: 2px;
    background-color: #e78000;
    position: absolute;
    left: 0;
    bottom: 0; }
  h1 a {
    font-size: 38px; }
  @media screen and (max-width: 1199px) {
    h1 {
      font-size: 32px;
      margin-bottom: 40px; }
      h1 a {
        font-size: 32px; } }
  @media screen and (max-width: 991px) {
    h1 {
      font-size: 28px;
      padding-bottom: 20px;
      margin-bottom: 30px; }
      h1 a {
        font-size: 28px; } }
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 24px;
      padding-bottom: 10px;
      margin-bottom: 20px; }
      h1 a {
        font-size: 24px; } }

h2 {
  font-family: "ProximaNovaBold";
  font-size: 36px;
  color: #292929;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 25px;
  margin: 0 0 60px; }
  h2:after {
    content: '';
    width: 67px;
    height: 2px;
    background-color: #e78000;
    position: absolute;
    left: 0;
    bottom: 0; }
  h2 a {
    font-size: 36px;
    color: #282727; }
  @media screen and (max-width: 1199px) {
    h2 {
      font-size: 30px;
      margin-bottom: 40px; }
      h2 a {
        font-size: 30px; } }
  @media screen and (max-width: 991px) {
    h2 {
      font-size: 24px;
      padding-bottom: 20px;
      margin-bottom: 30px; }
      h2 a {
        font-size: 24px; } }
  @media screen and (max-width: 768px) {
    h2 {
      font-size: 22px;
      padding-bottom: 10px;
      margin-bottom: 20px; }
      h2 a {
        font-size: 22px; } }

h3 {
  font-family: "ProximaNovaBold";
  font-size: 24px;
  color: #292929;
  text-transform: uppercase; }
  @media screen and (max-width: 991px) {
    h3 {
      font-size: 22px; } }
  @media screen and (max-width: 768px) {
    h3 {
      font-size: 20px; } }

h4 {
  font-family: "ProximaNovaBold";
  font-size: 22px;
  color: #292929;
  text-transform: uppercase; }
  @media screen and (max-width: 991px) {
    h4 {
      font-size: 20px; } }
  @media screen and (max-width: 768px) {
    h4 {
      font-size: 18px; } }

h5 {
  font-family: "ProximaNovaBold";
  font-size: 18px;
  color: #292929; }

h6 {
  font-family: "ProximaNovaBold";
  font-size: 16px;
  text-transform: uppercase;
  color: #292929; }

a {
  color: #292929;
  text-decoration: none; }
  a:focus {
    color: #292929;
    text-decoration: none; }
  a:hover {
    color: #e78000;
    text-decoration: none; }

textarea,
button,
select,
input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"] {
  border-radius: 0px;
  -webkit-appearance: none; }

.main, .page {
  position: relative;
  flex: 1 0 auto;
  font-size: 16px; }
  .main textarea,
  .main input[type="text"],
  .main input[type="email"],
  .main input[type="search"],
  .main input[type="password"], .page textarea,
  .page input[type="text"],
  .page input[type="email"],
  .page input[type="search"],
  .page input[type="password"] {
    width: 100%;
    min-height: 50px;
    background-color: #fff;
    font-family: "ProximaNovaRegular";
    font-size: 16px;
    padding: 13px 20px;
    margin-bottom: 20px;
    border-radius: 2px;
    border: 1px solid #dddddd;
    box-shadow: none; }
    .main textarea::placeholder,
    .main input[type="text"]::placeholder,
    .main input[type="email"]::placeholder,
    .main input[type="search"]::placeholder,
    .main input[type="password"]::placeholder, .page textarea::placeholder,
    .page input[type="text"]::placeholder,
    .page input[type="email"]::placeholder,
    .page input[type="search"]::placeholder,
    .page input[type="password"]::placeholder {
      color: #7e7e7e; }
  @media screen and (max-width: 991px) {
    .main textarea,
    .main input[type="text"],
    .main input[type="email"],
    .main input[type="search"],
    .main input[type="password"], .page textarea,
    .page input[type="text"],
    .page input[type="email"],
    .page input[type="search"],
    .page input[type="password"] {
      min-height: 40px;
      padding: 8px 15px;
      margin-bottom: 15px; } }

.main .container {
  padding-top: 100px;
  padding-bottom: 100px; }
  @media screen and (max-width: 1199px) {
    .main .container {
      padding-top: 50px;
      padding-bottom: 50px; } }
  @media screen and (max-width: 991px) {
    .main .container {
      padding-top: 30px;
      padding-bottom: 30px; } }

.close {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 8px;
  top: 10px;
  opacity: 1;
  outline: none; }
  .close:before, .close:after {
    content: '';
    width: 25px;
    height: 2px;
    background-color: #e78000;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: -1px auto 0;
    transition: 300ms; }
  .close:before {
    transform: rotate(45deg); }
  .close:after {
    transform: rotate(-45deg); }
  .close:hover:before {
    transform: rotate(135deg); }
  .close:hover:after {
    transform: rotate(45deg); }

.btn {
  display: table;
  height: 50px;
  width: auto !important;
  background-color: #e78000 !important;
  font-size: 16px;
  font-weight: normal;
  color: #fff !important;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 0px;
  border: 2px solid #e78000;
  padding: 14px 30px !important;
  border-radius: 3px;
  transition: 300ms;
  line-height: 1; }
  .btn:focus, .btn:active {
    background-color: #e78000 !important;
    color: #fff; }
  .btn:hover, .btn.active {
    background-color: #fff !important;
    color: #e78000 !important; }
  @media screen and (max-width: 991px) {
    .btn {
      height: 40px;
      padding: 10px 25px !important; } }

.square--list {
  list-style: none;
  margin: 0;
  padding: 0; }
  .square--list li {
    display: block;
    position: relative;
    padding-left: 17px;
    margin-bottom: 5px; }
    .square--list li:before {
      content: '';
      width: 6px;
      height: 6px;
      background-color: #e78000;
      position: absolute;
      left: 0;
      top: 7px; }

.col--location,
.col--phone,
.col--email {
  position: relative;
  padding-left: 25px;
  font-size: 16px; }
  .col--location:after,
  .col--phone:after,
  .col--email:after {
    content: '';
    font-family: 'fontAwesome';
    font-size: 16px;
    color: #e78000;
    position: absolute;
    top: 1px;
    left: 0; }

.col--location:after {
  content: '\f041'; }

.col--phone:after {
  content: '\f095';
  top: 3px; }

.col--email:after {
  content: '\f0e0';
  font-size: 14px;
  top: 3px; }

.col--email a {
  text-decoration: underline; }
  .col--email a:hover {
    text-decoration: none; }

.grd {
  background: linear-gradient(to left, #e87e00 10%, #db0000, #140001 90%); }

.gray {
  background-color: #f4f5f9; }

.line--title {
  position: relative;
  padding-bottom: 18px; }
  .line--title:after {
    content: '';
    width: 67px;
    height: 2px;
    background-color: #e78000;
    position: absolute;
    left: 0;
    bottom: 0; }
  @media screen and (max-width: 991px) {
    .line--title {
      padding-bottom: 10px; } }

/* header */
header {
  height: 135px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  /* header logo */
  /* header logo */
  /* header nav */
  /* header nav */
  /* header search */
  /* header search */
  /* header langs */
  /* header langs */ }
  header ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  header .logo {
    display: inline-block;
    vertical-align: top;
    margin: 20px 0 0 0; }
    header .logo img {
      display: block; }
  header .header--info {
    display: inline-flex;
    width: 845px;
    height: 95px;
    align-items: center;
    color: #fff; }
    header .header--info p {
      margin: 0; }
    header .header--info a {
      white-space: nowrap;
      color: #fff; }
    header .header--info .col {
      margin-left: 35px; }
  header .down {
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1); }
  header .nav {
    max-width: 900px;
    margin-left: 22px; }
    header .nav > ul {
      display: flex;
      justify-content: space-between; }
      header .nav > ul > li {
        position: relative; }
        header .nav > ul > li > a {
          display: flex;
          height: 80px;
          align-items: center;
          font-family: "Conv_ProximaNova-Medium";
          font-size: 18px;
          color: #292929;
          text-transform: uppercase;
          padding: 10px 17px 10px 10px; }
          header .nav > ul > li > a:hover {
            color: #e78000; }
        header .nav > ul > li.active > a {
          color: #e78000; }
        header .nav > ul > li.submenu > a:after {
          content: '\f107';
          font-family: 'fontAwesome';
          font-size: 18px;
          color: #292929;
          position: absolute;
          right: 0;
          top: 49%;
          transform: translateY(-50%);
          transition: 400ms; }
        header .nav > ul > li.submenu > a:hover:after {
          transform: translateY(-50%) rotate(-180deg);
          color: #e78000; }
        header .nav > ul > li ul {
          display: none;
          width: 420px;
          background-color: #575757;
          position: absolute;
          top: 100%;
          left: -40px;
          padding: 40px 20px 40px 48px; }
          header .nav > ul > li ul li {
            margin: 7.5px 0; }
            header .nav > ul > li ul li a {
              font-size: 16px;
              color: #fff;
              text-transform: uppercase; }
              header .nav > ul > li ul li a:hover {
                color: #e78000; }
            header .nav > ul > li ul li.active a {
              color: #e78000; }
  header .search {
    display: flex;
    width: 35px;
    flex-wrap: nowrap;
    position: absolute;
    top: 50%;
    right: 145px;
    overflow: hidden;
    transform: translateY(-50%);
    transition: 300ms;
    border: 1px solid transparent;
    padding-right: 35px;
    z-index: 1; }
    header .search input {
      width: 100%;
      border: none;
      padding: 10px 15px;
      outline: none;
      transition: 300ms; }
    header .search button {
      background-color: transparent;
      font-family: 'fontAwesome';
      font-size: 20px;
      color: #292929;
      padding: 5px;
      outline: none;
      border: none;
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%); }
      header .search button:after {
        content: '\f002'; }
    header .search:hover {
      width: 400px;
      border: 1px solid #e78000; }
  header .langs {
    position: absolute;
    padding-right: 17px;
    right: 27px;
    top: 50%;
    transform: translateY(-50%); }
    header .langs:after {
      content: '\f107';
      font-family: 'fontAwesome';
      font-size: 18px;
      color: #292929;
      position: absolute;
      right: 0;
      top: 45%;
      transform: translateY(-50%); }
    header .langs .langs__title {
      font-family: "Conv_ProximaNova-Medium";
      font-size: 18px;
      color: #292929;
      text-transform: uppercase;
      cursor: pointer; }
    header .langs ul {
      display: flex;
      width: 45px;
      height: 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: rgba(41, 41, 41, 0.8);
      padding: 0 10px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 3px;
      overflow: hidden;
      transition: 200ms;
      line-height: 1; }
      header .langs ul li {
        padding: 3px 0;
        margin: 0; }
        header .langs ul li a {
          display: block;
          font-family: "Conv_ProximaNova-Medium";
          font-size: 16px;
          color: #fff;
          text-transform: uppercase; }
          header .langs ul li a:hover {
            color: #e78000; }
        header .langs ul li.active a {
          color: #e78000; }
    header .langs.open ul {
      height: 80px;
      padding: 10px 10px; }
  @media screen and (min-width: 992px) {
    header .down {
      position: relative; } }
  @media screen and (max-width: 1199px) {
    header .header--info {
      width: 700px;
      justify-content: flex-end; }
      header .header--info .col {
        margin-left: 50px; }
    header .nav {
      max-width: 785px;
      margin: 0 0 0 15px; }
      header .nav > ul > li ul {
        width: 300px;
        padding: 15px 20px 15px 20px;
        left: 0; }
    header .search {
      right: 80px; } }
  @media screen and (max-width: 991px) {
    header {
      height: 80px; }
      header .logo {
        width: 150px;
        display: table;
        margin: 20px auto 0; }
        header .logo img {
          width: 100%; }
      header .header--info {
        display: none;
        max-width: 100%;
        width: auto;
        height: auto;
        background: linear-gradient(to left, #e87e00 10%, #db0000, #140001 90%);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        padding: 15px;
        z-index: 2; }
        header .header--info .col {
          display: table;
          margin: 5px auto; }
      header .nav {
        display: none;
        max-width: initial;
        max-height: calc(100% - 80px);
        background-color: #fff;
        position: fixed;
        top: 80px;
        left: 0;
        right: 0;
        padding: 15px;
        margin: 0;
        overflow: auto;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
        z-index: 1; }
        header .nav > ul {
          display: block; }
          header .nav > ul > li > a {
            display: table;
            height: auto;
            position: relative;
            font-size: 14px;
            padding: 5px 17px 5px 0; }
          header .nav > ul > li ul {
            background-color: transparent;
            width: 100%;
            position: relative;
            padding: 5px 10px;
            top: 0;
            border-top: 1px solid #e78000;
            border-bottom: 1px solid #e78000; }
            header .nav > ul > li ul li a {
              font-size: 14px;
              color: #292929; }
      header .nav--btn {
        width: 35px;
        height: 30px;
        position: absolute;
        left: 12px;
        top: 50%;
        padding: 0;
        margin: 0;
        outline: none;
        float: none;
        transform: translateY(-50%); }
        header .nav--btn span {
          position: absolute;
          left: 0px;
          right: 0;
          width: 85%;
          height: 3px;
          margin: 0 auto;
          background-color: #fff;
          transition: 350ms; }
          header .nav--btn span:nth-of-type(1) {
            top: 7px; }
          header .nav--btn span:nth-of-type(2) {
            top: 14px; }
          header .nav--btn span:nth-of-type(3) {
            top: 21px; }
        header .nav--btn.open span:nth-of-type(1) {
          top: 14px;
          transform: rotate(45deg); }
        header .nav--btn.open span:nth-of-type(2) {
          opacity: 0; }
        header .nav--btn.open span:nth-of-type(3) {
          top: 14px;
          transform: rotate(-45deg); }
      header .search {
        display: none;
        left: 0;
        right: 0;
        width: auto;
        background-color: #fff;
        border: 2px solid #e78000;
        transition: 0ms; }
        header .search:hover {
          width: auto;
          border: 2px solid #e78000; }
      header .search--btn {
        display: block;
        font-family: 'fontAwesome';
        font-size: 22px;
        color: #fff;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%); }
        header .search--btn:after {
          content: '\f002'; }
        header .search--btn:hover {
          text-decoration: none; }
      header .phones--btn {
        display: block;
        font-family: 'fontAwesome';
        font-size: 24px;
        color: #fff;
        position: absolute;
        right: 55px;
        top: 50%;
        transform: translateY(-50%); }
        header .phones--btn:after {
          content: '\f095'; }
        header .phones--btn:hover {
          text-decoration: none; }
      header .langs {
        right: auto;
        left: 60px; }
        header .langs:after {
          color: #fff; }
        header .langs .langs__title {
          color: #fff; }
        header .langs ul {
          z-index: 1; }
        header .langs.open ul {
          padding: 5px 10px; } }
  @media screen and (max-width: 480px) {
    header .logo {
      width: 140px;
      margin-top: 25px; }
    header .nav--btn {
      left: 5px; }
    header .langs {
      left: 45px; }
      header .langs:after {
        font-size: 16px;
        top: 40%; }
      header .langs .langs__title {
        font-size: 16px; }
      header .langs ul li a {
        font-size: 14px; }
      header .langs.open ul {
        height: 70px; }
    header .search--btn {
      font-size: 20px;
      right: 10px; }
    header .phones--btn {
      font-size: 20px;
      right: 45px; } }

/* header */
/* main content */
.modal-open {
  overflow-y: visible; }
  .modal-open .modal {
    overflow: auto; }

.fancybox-enabled {
  overflow: visible; }
  .fancybox-enabled body {
    overflow-x: hidden; }

/* main slider */
.slider {
  opacity: 0;
  transition: 400ms;
  margin-bottom: 0px; }
  .slider.slick-initialized {
    opacity: 1; }
  .slider .slick-slide {
    height: calc(100vh - 135px);
    overflow: hidden;
    position: relative;
    outline: none; }
    .slider .slick-slide:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5) url(../img/bg.png) repeat center center; }
    .slider .slick-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center; }
    .slider .slick-slide .slider__block {
      width: 1170px;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      font-size: 24px;
      text-align: center;
      color: #fff;
      padding: 0 35px;
      margin: 0 auto;
      transform: translateY(-50%);
      z-index: 1; }
      .slider .slick-slide .slider__block .slider__block--title {
        font-family: "ProximaNovaBold";
        font-size: 52px;
        color: #fff;
        text-transform: uppercase;
        margin: 0 0 15px; }
      .slider .slick-slide .slider__block .btn {
        margin: 50px auto 0; }
  .slider .slick-dots {
    bottom: 30px; }
    .slider .slick-dots li {
      margin: 0px 2px; }
      .slider .slick-dots li button {
        width: 15px;
        height: 15px;
        background-color: #efefef;
        border-radius: 100%;
        border: 1px solid #3361eb; }
        .slider .slick-dots li button:before {
          display: none; }
        .slider .slick-dots li button:hover {
          background-color: #3361eb; }
      .slider .slick-dots li.slick-active button {
        background-color: #3361eb; }
  .slider.arrow .slick-arrow.slick-prev {
    left: 50%;
    margin-left: -570px; }
  .slider.arrow .slick-arrow.slick-next {
    right: 50%;
    margin-right: -570px; }
  @media screen and (max-width: 1280px) {
    .slider .slick-slide {
      height: auto; }
      .slider .slick-slide .slider__block {
        font-size: 22px; }
        .slider .slick-slide .slider__block .slider__block--title {
          font-size: 42px; }
        .slider .slick-slide .slider__block .btn {
          margin-top: 30px; }
    .slider.arrow .slick-arrow.slick-prev {
      left: 15px;
      margin: 0; }
    .slider.arrow .slick-arrow.slick-next {
      right: 15px;
      margin: 0; } }
  @media screen and (max-width: 1199px) {
    .slider .slick-slide .slider__block {
      width: auto;
      font-size: 20px;
      padding: 0 50px; }
      .slider .slick-slide .slider__block .slider__block--title {
        font-size: 36px; } }
  @media screen and (max-width: 991px) {
    .slider .slick-slide .slider__block {
      font-size: 18px; }
      .slider .slick-slide .slider__block .slider__block--title {
        font-size: 28px; }
      .slider .slick-slide .slider__block .btn {
        margin-top: 20px; } }
  @media screen and (max-width: 768px) {
    .slider .slick-slide .slider__block {
      line-height: 1.2; }
      .slider .slick-slide .slider__block .slider__block--title {
        display: block;
        font-size: 24px;
        margin-bottom: 5px; } }
  @media screen and (max-width: 600px) {
    .slider .slick-slide {
      height: 260px; }
      .slider .slick-slide .slider__block {
        font-size: 16px;
        padding: 0 35px; }
        .slider .slick-slide .slider__block .slider__block--title {
          font-size: 20px; }
    .slider.arrow .slick-arrow.slick-prev {
      left: 10px; }
    .slider.arrow .slick-arrow.slick-next {
      right: 10px; } }

.arrow .slick-arrow {
  width: 23px;
  height: 42px;
  z-index: 1;
  transition: 300ms; }
  .arrow .slick-arrow:before {
    display: none; }
  .arrow .slick-arrow:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: url(../img/next.png) no-repeat center center;
    background-size: contain; }
  .arrow .slick-arrow.slick-prev {
    left: 0; }
    .arrow .slick-arrow.slick-prev:after {
      transform: rotate(-180deg); }
  .arrow .slick-arrow.slick-next {
    right: 0; }
  .arrow .slick-arrow:hover {
    opacity: 0.7; }

@media screen and (max-width: 991px) {
  .arrow .slick-arrow {
    width: 18px;
    height: 30px; } }

/* main slider */
/* main advantages */
.advantages__block {
  display: flex;
  max-width: 1170px;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 70px 15px;
  margin: 0 auto; }
  .advantages__block .col {
    max-width: 220px;
    text-align: center;
    padding: 0 25px; }
    .advantages__block .col .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 67px;
      height: 67px;
      border-radius: 3px;
      background-color: #e78000;
      padding: 5px;
      margin: 0 auto 25px; }
      .advantages__block .col .icon img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        object-position: center center; }
    .advantages__block .col h5 {
      text-transform: inherit;
      line-height: 1.3;
      margin: 0; }
  @media screen and (max-width: 1199px) {
    .advantages__block .col {
      padding: 0 15px; }
      .advantages__block .col h5 {
        font-size: 16px; } }
  @media screen and (max-width: 991px) {
    .advantages__block {
      justify-content: center;
      padding: 50px 15px; }
      .advantages__block .col {
        max-width: initial;
        width: 33.33%;
        margin: 15px 0; } }
  @media screen and (max-width: 768px) {
    .advantages__block {
      padding: 30px 15px; } }
  @media screen and (max-width: 520px) {
    .advantages__block .col {
      width: 50%; } }
  @media screen and (max-width: 420px) {
    .advantages__block .col {
      width: 100%;
      padding: 0; }
      .advantages__block .col .icon {
        margin-bottom: 10px; } }

/* main advantages */
/* main catalog */
.cat {
  padding-bottom: 70px; }
  .cat .container {
    padding-bottom: 0; }
  @media screen and (max-width: 1280px) {
    .cat {
      padding-bottom: 50px; } }
  @media screen and (max-width: 1199px) {
    .cat {
      padding-bottom: 30px; } }
  @media screen and (max-width: 991px) {
    .cat {
      padding-bottom: 20px; } }

.cat__block {
  display: flex;
  flex-wrap: wrap;
  padding: 0 15px; }
  .cat__block .cat__col {
    width: calc(33.33% - 30px);
    height: 350px;
    overflow: hidden;
    border-radius: 3px;
    position: relative;
    margin: 15px; }
    .cat__block .cat__col:before {
      content: '';
      background-color: #282828;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.5;
      transition: 300ms; }
    .cat__block .cat__col img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center; }
    .cat__block .cat__col h3 {
      color: #fff;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 15px;
      right: 15px;
      transform: translateY(-50%);
      margin: 0; }
    .cat__block .cat__col .more {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 70px;
      color: #fff;
      text-transform: uppercase;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -100%;
      padding: 10px 15px;
      transition: 500ms;
      opacity: 0; }
    .cat__block .cat__col:hover:before {
      opacity: 0.8; }
    .cat__block .cat__col:hover .more {
      opacity: 1;
      bottom: 0;
      transition: 300ms; }
  @media screen and (max-width: 1440px) {
    .cat__block .cat__col {
      height: 280px; }
      .cat__block .cat__col h3 {
        font-size: 20px; } }
  @media screen and (max-width: 1280px) {
    .cat__block .cat__col {
      height: 240px; }
      .cat__block .cat__col .more {
        min-height: 50px;
        bottom: 0;
        opacity: 1; } }
  @media screen and (max-width: 991px) {
    .cat__block .cat__col {
      height: 220px; }
      .cat__block .cat__col h3 {
        font-size: 18px; } }
  @media screen and (max-width: 768px) {
    .cat__block {
      padding: 0 10px; }
      .cat__block .cat__col {
        width: calc(50% - 20px);
        margin: 10px 10px; }
        .cat__block .cat__col .more {
          min-height: 40px; } }
  @media screen and (max-width: 480px) {
    .cat__block {
      padding: 0 15px; }
      .cat__block .cat__col {
        width: 100%;
        margin: 0 0 20px; } }

/* main catalog */
/* main about */
.about__block {
  display: flex;
  align-items: stretch; }
  .about__block .left {
    width: 45%;
    min-height: 600px; }
    .about__block .left img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center; }
  .about__block .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 665px;
    padding: 25px 25px 25px 125px; }
  .about__block .btn {
    width: 200px !important;
    margin-top: 75px; }
  @media screen and (max-width: 1440px) {
    .about__block .left {
      min-height: 0px; }
    .about__block .right {
      padding-left: 100px;
      padding-top: 40px;
      padding-bottom: 40px; }
    .about__block .btn {
      margin-top: 35px; }
    .about__block h1 {
      margin-bottom: 40px; } }
  @media screen and (max-width: 1280px) {
    .about__block .right {
      padding: 25px 15px 25px 25px; }
    .about__block h1 {
      margin-bottom: 25px; }
    .about__block .btn {
      margin-top: 20px; } }
  @media screen and (max-width: 991px) {
    .about__block {
      flex-direction: column; }
      .about__block h1 {
        margin-bottom: 15px; }
      .about__block .left {
        width: 100%;
        order: 2; }
      .about__block .right {
        min-height: 0px;
        width: 100%;
        order: 1; }
        .about__block .right img {
          height: auto; }
      .about__block .btn {
        margin-top: 10px; } }

/* main about */
/* main partners */
.partners .container {
  padding: 65px 15px; }

@media screen and (max-width: 1280px) {
  .partners .container {
    padding: 30px 15px; } }

@media screen and (max-width: 768px) {
  .partners .container {
    padding: 15px; } }

.partners__slider .slick-slide {
  display: flex;
  height: 200px;
  align-items: center;
  outline: none;
  position: relative;
  padding: 15px 65px;
  /* border-right: 1px solid #d5d5d9; */ }
  .partners__slider .slick-slide img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    object-position: center center;
    filter: grayscale(100%);
    transition: 300ms; }
  .partners__slider .slick-slide:hover img {
    filter: grayscale(0); }

.partners__slider.arrow .slick-arrow:after {
  background: url(../img/next1.png) no-repeat center center;
  background-size: contain; }

@media screen and (max-width: 1280px) {
  .partners__slider .slick-slide {
    height: 175px;
    padding: 15px 40px; } }

@media screen and (max-width: 991px) {
  .partners__slider {
    padding: 0 25px; }
    .partners__slider .slick-slide {
      height: 150px;
      padding: 0px 15px; }
      .partners__slider .slick-slide img {
        filter: grayscale(0); } }

@media screen and (max-width: 480px) {
  .partners__slider .slick-slide {
    height: 120px; } }

/* main partners */
/* main news */
.news .container {
  padding-top: 0; }

.news__block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .news__block .news__block--col {
    display: flex;
    flex-direction: column;
    width: calc(50% - 30px);
    color: #292929; }
    .news__block .news__block--col .news__block--img {
      height: 280px;
      border-radius: 3px 3px 0 0;
      overflow: hidden; }
      .news__block .news__block--col .news__block--img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        transition: 300ms; }
    .news__block .news__block--col .news__block--info {
      flex: 1;
      background-color: #f4f5f9;
      padding: 40px 30px;
      position: relative;
      transition: 300ms; }
      .news__block .news__block--col .news__block--info:after {
        content: '';
        background: linear-gradient(to left, #e87e00 10%, #db0000, #140001 90%);
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        transition: 300ms; }
      .news__block .news__block--col .news__block--info .date {
        display: block;
        color: #525253;
        margin-bottom: 25px;
        transition: 300ms; }
      .news__block .news__block--col .news__block--info h5 {
        min-height: 38px;
        margin: 0 0 10px;
        transition: 300ms; }
      .news__block .news__block--col .news__block--info p {
        margin-bottom: 0; }
      .news__block .news__block--col .news__block--info * {
        position: relative;
        z-index: 1; }
    .news__block .news__block--col:hover .news__block--img img {
      transform: scale(1.1); }
    .news__block .news__block--col:hover .news__block--info {
      color: #fff; }
      .news__block .news__block--col:hover .news__block--info:after {
        opacity: 1; }
      .news__block .news__block--col:hover .news__block--info .date {
        color: #fff; }
      .news__block .news__block--col:hover .news__block--info h5 {
        color: #fff; }
  @media screen and (max-width: 1280px) {
    .news__block .news__block--col {
      width: calc(50% - 15px); }
      .news__block .news__block--col .news__block--info {
        padding: 20px 25px; }
        .news__block .news__block--col .news__block--info .date {
          margin-bottom: 15px; } }
  @media screen and (max-width: 1199px) {
    .news__block .news__block--col .news__block--img {
      height: 240px; }
    .news__block .news__block--col .news__block--info .date {
      margin-bottom: 10px; } }
  @media screen and (max-width: 991px) {
    .news__block .news__block--col .news__block--img {
      height: 200px; } }
  @media screen and (max-width: 768px) {
    .news__block .news__block--col .news__block--info {
      color: #fff; }
      .news__block .news__block--col .news__block--info:after {
        opacity: 1; }
      .news__block .news__block--col .news__block--info .date, .news__block .news__block--col .news__block--info h5 {
        color: #fff; } }
  @media screen and (max-width: 600px) {
    .news__block {
      flex-wrap: wrap; }
      .news__block .news__block--col {
        width: 100%;
        margin-bottom: 20px; }
        .news__block .news__block--col .news__block--img {
          height: auto; }
        .news__block .news__block--col .news__block--info {
          padding: 15px 20px; }
          .news__block .news__block--col .news__block--info h5 {
            min-height: 0px; } }

/* main news */
/* main content */
/* page content */
.page {
  padding-bottom: 80px; }
  .page .news1 .date, .page .news .date {
    color: #e78000; }
  .page .page_nav .active {
    color: #e78000; }
  .page #ask-form {
    max-width: 350px; }
  .page .write {
    color: #e78000;
    text-decoration: none; }
    .page .write:hover {
      text-decoration: underline; }
  .page .back_link {
    margin: 25px 0; }
  .page .search--active {
    font-family: "ProximaNovaBold" !important;
    color: #e78000 !important; }
  .page .back {
    display: table;
    color: #e78000;
    text-decoration: underline;
    margin: 15px 0; }
    .page .back:hover {
      text-decoration: none; }
  @media screen and (max-width: 1170px) {
    .page {
      font-size: 16px; }
      .page #photos-list td img {
        width: 100%;
        height: auto; } }
  @media screen and (max-width: 991px) {
    .page {
      padding-bottom: 50px; } }
  @media screen and (max-width: 768px) {
    .page {
      padding-bottom: 30px; } }
  @media screen and (max-width: 480px) {
    .page p img, .page p strong img {
      width: 100% !important;
      height: auto !important;
      display: block !important;
      float: none !important;
      margin: 0 0 10px 0 !important; }
    .page #photos-list td {
      width: 50%;
      display: inline-block;
      vertical-align: top; } }

/* breadcrumbs */
.breadcrumbs {
  background-color: #f4f5f9;
  font-family: "Conv_ProximaNova-Medium";
  font-size: 16px;
  padding-top: 58px;
  padding-bottom: 15px;
  margin-bottom: 75px; }
  .breadcrumbs ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .breadcrumbs ul li {
      display: inline-block;
      vertical-align: top;
      padding: 5px 0px;
      color: #e78000; }
      .breadcrumbs ul li a {
        font-size: 16px;
        text-decoration: none;
        color: #292929; }
        .breadcrumbs ul li a:hover {
          color: #e78000; }
      .breadcrumbs ul li:after {
        content: '';
        display: inline-block;
        vertical-align: top;
        height: 16px;
        width: 2px;
        background-color: #292929;
        margin: 2px 5px 0px 7px;
        color: #e78000; }
      .breadcrumbs ul li:last-of-type:after {
        display: none; }
  @media screen and (max-width: 991px) {
    .breadcrumbs {
      font-size: 14px;
      padding: 15px 0;
      margin-bottom: 50px; }
      .breadcrumbs ul li:after {
        margin-top: 0px; }
      .breadcrumbs ul li a {
        font-size: 14px; } }
  @media screen and (max-width: 768px) {
    .breadcrumbs {
      padding: 5px 0;
      margin-bottom: 30px; } }

/* breadcrumbs */
/* page about */
.page .about__block {
  align-items: stretch;
  position: relative;
  padding-bottom: 100px; }
  .page .about__block:after {
    content: '';
    height: 485px;
    background-color: #f4f5f9;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1; }
  .page .about__block .left {
    overflow: hidden;
    border-radius: 0px 10px 10px 0px; }
  .page .about__block .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 0;
    padding-bottom: 0; }
  .page .about__block .advantages__block {
    justify-content: center;
    padding: 0;
    margin-left: -125px;
    margin-top: 25px; }
    .page .about__block .advantages__block .col {
      width: 33.33%;
      margin: 50px 0 0 0; }

.page .partners {
  margin-bottom: -80px; }

@media screen and (max-width: 1280px) {
  .page .about__block .advantages__block {
    margin-left: -15px; } }

@media screen and (max-width: 1199px) {
  .page .about__block .advantages__block {
    margin-left: -50px; } }

@media screen and (max-width: 991px) {
  .page .about__block {
    width: 750px;
    padding-bottom: 0;
    margin: 0 auto; }
    .page .about__block:after {
      display: none; }
    .page .about__block .advantages__block {
      margin-left: 0;
      margin-top: 0;
      margin-bottom: 25px; }
      .page .about__block .advantages__block .col {
        margin: 25px 0; }
    .page .about__block .left {
      border-radius: 0px; }
    .page .about__block .right {
      padding: 0 15px; }
  .page .partners {
    margin-bottom: -50px; } }

@media screen and (max-width: 768px) {
  .page .partners {
    margin-bottom: -30px; } }

@media screen and (max-width: 767px) {
  .page .about__block {
    width: 100%; } }

@media screen and (max-width: 520px) {
  .page .about__block .advantages__block .col {
    width: 50%; } }

@media screen and (max-width: 420px) {
  .page .about__block .advantages__block .col {
    width: 100%;
    margin: 15px 0; } }

/* page about */
/* page catalog */
.cat--inside {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px; }
  .cat--inside .cat--inside__col {
    display: flex;
    flex-direction: column;
    width: calc(25% - 30px);
    margin: 0 15px 30px; }
    .cat--inside .cat--inside__col .cat--inside__img {
      height: 265px; }
      .cat--inside .cat--inside__col .cat--inside__img img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center; }
    .cat--inside .cat--inside__col .cat--inside__block {
      flex: 1;
      background-color: #f4f5f9;
      padding: 30px;
      text-align: center;
      position: relative; }
      .cat--inside .cat--inside__col .cat--inside__block:after {
        content: '';
        background: linear-gradient(to left, #e87e00 10%, #db0000, #140001 90%);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        transition: 300ms; }
      .cat--inside .cat--inside__col .cat--inside__block h5 {
        min-height: 38px;
        margin: 0 0 10px;
        transition: 300ms; }
      .cat--inside .cat--inside__col .cat--inside__block .price {
        color: #e78000;
        transition: 300ms; }
      .cat--inside .cat--inside__col .cat--inside__block * {
        position: relative;
        z-index: 1; }
    .cat--inside .cat--inside__col:hover .cat--inside__block:after {
      opacity: 1; }
    .cat--inside .cat--inside__col:hover .cat--inside__block h5 {
      color: #fff; }
    .cat--inside .cat--inside__col:hover .cat--inside__block .price {
      color: #fff; }
  @media screen and (max-width: 1199px) {
    .cat--inside .cat--inside__col .cat--inside__img {
      height: 230px; }
    .cat--inside .cat--inside__col .cat--inside__block {
      padding: 20px; } }
  @media screen and (max-width: 991px) {
    .cat--inside {
      margin: 0 -10px; }
      .cat--inside .cat--inside__col {
        width: calc(33.33% - 20px);
        margin: 0 10px 20px; }
        .cat--inside .cat--inside__col .cat--inside__block h5 {
          font-size: 16px; } }
  @media screen and (max-width: 768px) {
    .cat--inside .cat--inside__col .cat--inside__img {
      height: 200px; } }
  @media screen and (max-width: 680px) {
    .cat--inside .cat--inside__col .cat--inside__img {
      height: 175px; } }
  @media screen and (max-width: 600px) {
    .cat--inside .cat--inside__col {
      width: calc(50% - 20px); }
      .cat--inside .cat--inside__col .cat--inside__img {
        height: 200px; } }
  @media screen and (max-width: 480px) {
    .cat--inside .cat--inside__col .cat--inside__img {
      height: 175px; } }
  @media screen and (max-width: 420px) {
    .cat--inside {
      margin: 0; }
      .cat--inside .cat--inside__col {
        width: 100%;
        margin: 0px 0px 20px; }
        .cat--inside .cat--inside__col .cat--inside__img {
          height: auto; }
          .cat--inside .cat--inside__col .cat--inside__img img {
            height: auto; } }

.cat--unit {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 65px; }
  .cat--unit .left {
    display: flex;
    width: 555px; }
  .cat--unit .right {
    width: calc(100% - 555px);
    padding-left: 30px; }
  .cat--unit .cat--unit__price {
    display: flex;
    align-items: flex-end;
    line-height: 1;
    margin: 25px 0 15px; }
    .cat--unit .cat--unit__price p {
      margin: 0 55px 5px 0; }
    .cat--unit .cat--unit__price .price {
      font-family: "ProximaNovaBold";
      font-size: 24px;
      color: #e78000; }
  .cat--unit h1 {
    font-size: 28px;
    margin-bottom: 40px; }
  @media screen and (max-width: 1199px) {
    .cat--unit h1 {
      font-size: 24px;
      margin-bottom: 20px; } }
  @media screen and (max-width: 991px) {
    .cat--unit {
      margin-bottom: 40px; }
      .cat--unit .left {
        width: 100%;
        order: 2; }
      .cat--unit .right {
        width: 100%;
        padding: 0;
        order: 1;
        margin-bottom: 20px; } }
  @media screen and (max-width: 420px) {
    .cat--unit .cat--unit__price {
      flex-direction: column;
      align-items: flex-start; }
      .cat--unit .cat--unit__price p {
        margin: 0; }
      .cat--unit .cat--unit__price .price {
        display: block;
        margin: 10px 0 0; } }

.cat--unit__for {
  width: 455px; }
  .cat--unit__for .slick-slide {
    height: 455px;
    outline: none;
    border-radius: 4px;
    overflow: hidden; }
    .cat--unit__for .slick-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center; }
  @media screen and (max-width: 600px) {
    .cat--unit__for {
      width: calc(100% - 100px); }
      .cat--unit__for .slick-slide {
        height: 355px; } }
  @media screen and (max-width: 480px) {
    .cat--unit__for {
      width: calc(100% - 95px); }
      .cat--unit__for .slick-slide {
        height: 300px; } }
  @media screen and (max-width: 380px) {
    .cat--unit__for {
      width: calc(100% - 85px); }
      .cat--unit__for .slick-slide {
        height: 250px; } }

.cat--unit__nav {
  width: 85px;
  padding: 32px 0;
  margin-right: 15px; }
  .cat--unit__nav .slick-slide {
    height: 80px;
    outline: none;
    margin: 8px 0;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    cursor: pointer; }
    .cat--unit__nav .slick-slide:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border: 3px solid #e78000;
      border-radius: 4px;
      opacity: 0;
      transition: 300ms; }
    .cat--unit__nav .slick-slide img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center; }
    .cat--unit__nav .slick-slide:hover:after, .cat--unit__nav .slick-slide.slick-current:after {
      opacity: 1; }
  .cat--unit__nav.arrow .slick-arrow {
    width: 10px;
    height: 22px;
    left: 0;
    right: 0;
    margin: 0 auto; }
    .cat--unit__nav.arrow .slick-arrow:after {
      background: url(../img/next1.png) no-repeat center center;
      background-size: contain; }
    .cat--unit__nav.arrow .slick-arrow.slick-prev {
      transform: rotate(90deg);
      top: 0; }
    .cat--unit__nav.arrow .slick-arrow.slick-next {
      transform: rotate(90deg);
      top: auto;
      bottom: 0; }
  @media screen and (max-width: 480px) {
    .cat--unit__nav {
      width: 75px;
      padding: 25px 0; }
      .cat--unit__nav .slick-slide {
        height: 72px;
        margin: 5px 0; } }
  @media screen and (max-width: 380px) {
    .cat--unit__nav {
      width: 65px; }
      .cat--unit__nav .slick-slide {
        height: 65px;
        margin: 2px 0; } }

.cat--unit__table {
  position: relative;
  z-index: 1;
  line-height: 1.2; }
  .cat--unit__table tr td {
    position: relative;
    padding: 5px 25px 5px 0; }
    .cat--unit__table tr td:first-of-type {
      min-width: 325px;
      vertical-align: bottom; }
      .cat--unit__table tr td:first-of-type:after {
        content: '';
        height: 1px;
        position: absolute;
        left: 0;
        bottom: 10px;
        right: 25px;
        border: 1px dotted #929292;
        z-index: -1; }
    .cat--unit__table tr td strong {
      background-color: #fff;
      font-weight: normal;
      color: #7e7e7e; }
  @media screen and (max-width: 1199px) {
    .cat--unit__table tr td:first-of-type {
      min-width: 0px; } }
  @media screen and (max-width: 991px) {
    .cat--unit__table tr td {
      padding: 5px 15px 5px 0; }
      .cat--unit__table tr td:first-of-type:after {
        right: 15px; }
      .cat--unit__table tr td:last-of-type {
        min-width: 100px; } }

.cat--unit__block {
  padding: 70px 0 35px;
  margin-bottom: -80px; }
  .cat--unit__block h3 {
    position: relative;
    padding-bottom: 20px;
    margin: 0 0 40px; }
    .cat--unit__block h3:after {
      content: '';
      width: 67px;
      height: 2px;
      background-color: #e78000;
      position: absolute;
      left: 0;
      bottom: 0; }
  .cat--unit__block .cat--unit__table tr td strong {
    background-color: #f4f5f9; }
  .cat--unit__block .cat--unit__table tr td:nth-of-type(1) {
    min-width: 350px; }
  .cat--unit__block .cat--unit__block--info {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch; }
    .cat--unit__block .cat--unit__block--info .col {
      width: 50%;
      position: relative;
      padding: 0 0 30px 20px; }
      .cat--unit__block .cat--unit__block--info .col:after {
        content: '';
        width: 1px;
        background-color: #d7d8da;
        position: absolute;
        top: 100px;
        right: 0;
        bottom: 0; }
      .cat--unit__block .cat--unit__block--info .col .title {
        display: block;
        background-color: #e78000;
        font-family: "ProximaNovaBold";
        font-size: 16px;
        color: #fff;
        text-transform: uppercase;
        padding: 20px;
        border-radius: 4px;
        line-height: 1;
        margin: 0 0 25px -20px; }
      .cat--unit__block .cat--unit__block--info .col:nth-child(odd) .title {
        border-radius: 4px 0px 0px 4px; }
      .cat--unit__block .cat--unit__block--info .col:nth-child(even):after {
        display: none; }
      .cat--unit__block .cat--unit__block--info .col:nth-child(even) .title {
        border-radius: 0px 4px 4px 0px; }
  @media screen and (max-width: 1199px) {
    .cat--unit__block h3 {
      padding-bottom: 15px;
      margin-bottom: 30px; }
    .cat--unit__block .cat--unit__table tr td:nth-of-type(1) {
      min-width: 0px; } }
  @media screen and (max-width: 991px) {
    .cat--unit__block {
      padding-top: 40px;
      margin-bottom: -50px; }
      .cat--unit__block h3 {
        padding-bottom: 10px;
        margin-bottom: 25px; }
      .cat--unit__block .cat--unit__block--info .col {
        padding-bottom: 20px; }
        .cat--unit__block .cat--unit__block--info .col:after {
          top: 65px; }
        .cat--unit__block .cat--unit__block--info .col .title {
          padding: 15px 20px;
          margin-bottom: 15px; } }
  @media screen and (max-width: 768px) {
    .cat--unit__block {
      padding-top: 30px;
      margin-bottom: -30px; }
      .cat--unit__block .cat--unit__block--info .col {
        width: 100%; }
        .cat--unit__block .cat--unit__block--info .col:after {
          display: none; }
        .cat--unit__block .cat--unit__block--info .col .title {
          border-radius: 4px !important; } }

/* page catalog */
/* pagination */
.pagination {
  width: 100%;
  text-align: center;
  margin: 40px 0 0 0; }
  .pagination ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%; }
    .pagination ul li {
      display: inline-block;
      vertical-align: top;
      margin: 0 0 5px 0; }
      .pagination ul li a {
        display: flex;
        width: 30px;
        height: 30px;
        font-family: "ProximaNovaBold";
        font-size: 18px;
        color: #261b17;
        border-radius: 2px;
        align-items: center;
        justify-content: center; }
        .pagination ul li a:hover {
          background-color: #e78000;
          color: #fff; }
      .pagination ul li.active a {
        background-color: #e78000;
        color: #fff; }
      .pagination ul li.prev a, .pagination ul li.next a {
        position: relative;
        background-color: transparent; }
        .pagination ul li.prev a:after, .pagination ul li.next a:after {
          content: '';
          background: url(../img/next1.png) no-repeat center center;
          background-size: 10px;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0; }
      .pagination ul li.next {
        margin-left: 15px; }
      .pagination ul li.prev {
        margin-right: 15px; }
        .pagination ul li.prev a:after {
          transform: rotate(-180deg); }
  @media screen and (max-width: 991px) {
    .pagination {
      margin-top: 20px; } }

/* pagination */
/* page contacts */
.cts {
  display: flex;
  justify-content: space-between;
  padding-right: 100px; }
  .cts ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .cts p {
    margin: 0; }
  .cts .cts__col {
    padding-right: 15px; }
  @media screen and (max-width: 1199px) {
    .cts {
      padding-right: 0px; } }
  @media screen and (max-width: 768px) {
    .cts {
      flex-direction: column; }
      .cts .cts__col {
        width: 100%;
        margin: 0 0 15px; } }

.map {
  height: 400px;
  margin: 45px 0 65px; }
  .map > ymaps, .map .ymaps-2-1-72-map {
    height: 100% !important;
    width: 100% !important; }
  @media screen and (max-width: 1199px) {
    .map {
      height: 300px;
      margin: 20px 0 35px; } }
  @media screen and (max-width: 991px) {
    .map {
      height: 250px; } }

.cts__form {
  padding: 65px 0 100px;
  margin: 0 0 -80px 0; }
  .cts__form h3 {
    margin: 0 0 40px; }
  .cts__form input[type="text"],
  .cts__form input[type="email"],
  .cts__form textarea {
    margin-bottom: 25px; }
  .cts__form .cts__form--field {
    display: flex;
    margin: 0 -15px; }
    .cts__form .cts__form--field input {
      width: calc(33.33% - 30px);
      margin-left: 15px;
      margin-right: 15px; }
  .cts__form .g-recaptcha {
    display: inline-block;
    vertical-align: top; }
  .cts__form .btn {
    display: inline-block;
    vertical-align: top;
    margin: 25px 0 0 200px; }
  @media screen and (max-width: 1199px) {
    .cts__form .btn {
      margin: 15px 0 0 100px; } }
  @media screen and (max-width: 991px) {
    .cts__form {
      padding: 40px 0 75px;
      margin-bottom: -50px; }
      .cts__form input[type="text"],
      .cts__form input[type="email"],
      .cts__form textarea {
        margin-bottom: 15px; }
      .cts__form .btn {
        float: right;
        margin-left: 0; } }
  @media screen and (max-width: 768px) {
    .cts__form {
      padding: 30px 0 50px;
      margin-bottom: -30px; }
      .cts__form h3 {
        margin-bottom: 20px; } }
  @media screen and (max-width: 600px) {
    .cts__form .cts__form--field {
      display: block;
      margin: 0; }
      .cts__form .cts__form--field input {
        width: 100%;
        margin-left: 0;
        margin-right: 0; } }
  @media screen and (max-width: 480px) {
    .cts__form {
      padding-bottom: 35px; }
      .cts__form .g-recaptcha {
        float: right; } }
  @media screen and (max-width: 380px) {
    .cts__form .g-recaptcha {
      transform: scale(0.9);
      transform-origin: 100% 50%; } }

/* page contacts */
/* modal */
.modal .modal-dialog {
  top: 45%;
  margin: 0 auto;
  transform: translateY(-50%) !important; }

.modal .modal-content {
  width: auto;
  max-width: 420px;
  border-radius: 0px;
  margin: 0 auto; }
  .modal .modal-content h4 {
    font-family: "ProximaNovaBold";
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    padding: 0 15px;
    margin: 0; }

.modal form {
  display: block;
  padding-bottom: 140px;
  margin-top: 25px; }
  .modal form textarea,
  .modal form input[type="text"],
  .modal form input[type="email"],
  .modal form input[type="search"],
  .modal form input[type="password"] {
    width: 100%;
    min-height: 50px;
    background-color: #fff;
    font-family: "ProximaNovaRegular";
    font-size: 16px;
    padding: 13px 20px;
    margin-bottom: 20px;
    border-radius: 2px;
    border: 1px solid #dddddd;
    box-shadow: none; }
    .modal form textarea::placeholder,
    .modal form input[type="text"]::placeholder,
    .modal form input[type="email"]::placeholder,
    .modal form input[type="search"]::placeholder,
    .modal form input[type="password"]::placeholder {
      color: #7e7e7e; }
  .modal form .g-recaptcha {
    float: right; }
  .modal form .btn {
    float: right;
    margin: 15px 0 0 0; }

@media screen and (max-width: 991px) {
  .modal form textarea,
  .modal form input[type="text"],
  .modal form input[type="email"],
  .modal form input[type="search"],
  .modal form input[type="password"] {
    min-height: 40px;
    padding: 8px 15px;
    margin-bottom: 15px; } }

@media screen and (max-width: 767px) {
  .modal .modal-content h4 {
    font-size: 16px; } }

@media screen and (max-width: 420px) {
  .modal .modal-dialog {
    margin: 0 10px; }
    .modal .modal-dialog h4 {
      font-size: 16px; } }

@media screen and (max-width: 380px) {
  .modal .modal-content form .g-recaptcha {
    transform: scale(0.85);
    transform-origin: 100% 50%; } }

.call--btn {
  position: fixed;
  bottom: 100px;
  right: 105px;
  display: table;
  background-color: #e78000;
  font-family: 'FontAwesome';
  border-radius: 0px;
  text-decoration: none !important;
  width: 70px;
  height: 70px;
  text-align: center;
  padding: 22px 5px 5px 5px;
  box-sizing: border-box;
  border-radius: 100%;
  outline: none !important;
  z-index: 1; }
  .call--btn i {
    display: block;
    font-size: 28px;
    color: #fff;
    transition: 300ms; }
  .call--btn:after, .call--btn:before {
    content: '';
    width: 90%;
    height: 90%;
    background-color: rgba(231, 128, 0, 0.9);
    position: absolute;
    top: 5%;
    left: 5%;
    animation-iteration-count: infinite;
    animation-duration: 1.2s;
    animation-name: ring;
    outline: none;
    border-radius: 100%;
    z-index: -1; }
  .call--btn:after {
    animation-delay: 0.6s; }
  .call--btn:before {
    animation-delay: 0.8s; }
  .call--btn:hover i {
    transform: scale(1.1); }
  @media screen and (max-width: 1440px) {
    .call--btn {
      right: 50px;
      bottom: 50px; } }
  @media screen and (max-width: 991px) {
    .call--btn {
      width: 50px;
      height: 50px;
      padding-top: 13px;
      right: 15px; }
      .call--btn i {
        font-size: 24px; } }
  @media screen and (max-width: 768px) {
    .call--btn {
      width: 40px;
      height: 40px;
      padding-top: 10px; } }

@keyframes ring {
  0% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0;
    transform: scale(1.7); } }

/* modal */
/* page content */
/* footer */
footer {
  background-color: #292929;
  font-size: 14px;
  color: #fff;
  padding-top: 75px; }
  footer ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    footer ul li {
      margin: 5px 0; }
      footer ul li.active a {
        color: #e78000; }
  footer a {
    color: #fff;
    transition: 300ms; }
    footer a:focus {
      color: #fff; }
    footer a:hover {
      color: #e78000; }
  footer .square--list li {
    padding-left: 0;
    margin: 5px 0; }
    footer .square--list li:before {
      left: -17px; }
  footer .col--location,
  footer .col--email,
  footer .col--phone {
    padding-left: 0;
    font-size: 14px; }
    footer .col--location:after,
    footer .col--email:after,
    footer .col--phone:after {
      left: -25px; }
  footer .col--phone:after {
    top: 0; }
  footer h3 {
    position: relative;
    color: #fff;
    padding-bottom: 20px;
    margin: 0 0 25px; }
    footer h3:after {
      content: '';
      width: 68px;
      height: 2px;
      background-color: #e78000;
      position: absolute;
      bottom: 0;
      left: 0; }
  footer .footer__cts ul li {
    margin: 5px 0 15px; }
  footer .down {
    padding: 15px 0;
    margin-top: 50px; }
    footer .down .container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center; }
      footer .down .container:before, footer .down .container:after {
        display: none; }
    footer .down p {
      margin: 0; }
    footer .down .socials li {
      display: inline-block;
      vertical-align: top;
      margin: 0 7.5px; }
      footer .down .socials li a {
        display: flex;
        width: 30px;
        height: 30px;
        background-color: rgba(255, 255, 255, 0.3);
        justify-content: center;
        align-items: center;
        font-size: 18px;
        color: #fff;
        border-radius: 3px; }
        footer .down .socials li a:hover {
          background-color: rgba(255, 255, 255, 0); }
    footer .down .artmedia:hover {
      color: #fff; }
  @media screen and (max-width: 1199px) {
    footer {
      padding-top: 50px; }
      footer .down {
        margin-top: 30px; }
      footer h3 {
        font-size: 20px;
        padding-bottom: 15px;
        margin-bottom: 20px; } }
  @media screen and (max-width: 991px) {
    footer {
      padding-top: 30px; }
      footer h3 {
        font-size: 18px;
        padding-bottom: 10px;
        margin-bottom: 15px; }
      footer .down {
        margin-top: 20px; } }
  @media screen and (max-width: 767px) {
    footer h3 {
      font-size: 16px; }
    footer .col-sm-4 {
      margin-bottom: 25px; }
    footer .square--list li {
      padding-left: 17px; }
      footer .square--list li:before {
        left: 0; }
    footer .col--location:after,
    footer .col--email:after,
    footer .col--phone:after {
      left: 0; }
    footer .footer__cts ul li {
      padding-left: 27px; }
    footer .down {
      margin-top: 10px; } }
  @media screen and (max-width: 720px) {
    footer .down {
      text-align: center; }
      footer .down .container {
        justify-content: center;
        flex-direction: column; }
      footer .down p {
        order: 2;
        margin: 10px 0; }
    footer .socials {
      order: 1;
      margin: 0 0 5px; }
    footer .artmedia {
      order: 3; } }

/* footer */
